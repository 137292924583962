import React, { Component } from "react";
import { Redirect, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { LoginAction } from "./redux/actions/LoginActions";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(LoginAction.login()),
  setUsername: (event) => dispatch(LoginAction.setUsername(event.target.value)),
  setPassword: (event) => dispatch(LoginAction.setPassword(event.target.value)),
  setRemember: (remember) => dispatch(LoginAction.setRemember(remember)),
});

class LoginScreen extends Component {
  componentDidMount() {
    if (this.props.loginState.token) {
      this.props.history.push("/data");
    }
  }
  render() {
    let { username, password, remember, token, busy } = this.props.loginState;
    if (token) {
      return <Redirect to="/data" />;
    }
    return (
      <>
        <div className="overlay-bg" onClick={this.props.dismiss}></div>
        <div className="overlay">
          <form className="overlay-content">
            <label htmlFor="email">Sähköposti</label>
            <input
              id="email"
              type="email"
              value={username}
              onChange={this.props.setUsername}
              onKeyPress={this.enterEmail}
              ref={(input) => {
                this.nameInput = input;
              }}
            />
            <label htmlFor="password">Salasana</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={this.props.setPassword}
              onKeyPress={this.enterPassword}
            />
            <label htmlFor="remember">Muista minut</label>
            <input
              id="remember"
              type="checkbox"
              checked={!!remember}
              onChange={this.toggleRemember}
            />
            <input
              className="button"
              type="button"
              disabled={busy}
              value="Kirjaudu"
              onClick={this.props.login}
            />
            <p className="cancel">
              <Link to="/">Peruuta</Link>
            </p>
            <p className="forgot-password">
              <Link to="/salasanan-palautus/">Unohditko salasanasi?</Link>
            </p>
          </form>
        </div>
      </>
    );
  }

  toggleRemember = () => {
    let newRemember = true;
    try {
      newRemember = !this.props.loginState.remember;
    } catch (e) {
      //nothing
    }
    this.props.setRemember(newRemember);
  };
  enterEmail = (evt) => {
    if (evt.key === "Enter") {
      document.getElementById("password").focus();
    }
  };
  enterPassword = (evt) => {
    if (evt.key === "Enter") {
      this.props.login();
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
);
