import { backendServer } from "../../variables";
import moment from "moment";

const setUsername = (username) => {
  return {
    type: "EDIT_SETTING",
    payload: {
      username: username,
    },
  };
};

const setPassword = (password) => {
  return {
    type: "EDIT_SETTING",
    payload: {
      password: password,
    },
  };
};
const setPassword2 = (password) => {
  return {
    type: "EDIT_SETTING",
    payload: {
      password2: password,
    },
  };
};

//Set error message and clear done flag
const setError = (error) => {
  return {
    type: "EDIT_SETTING",
    payload: {
      error,
      done: false,
    },
  };
};

const clearSettings = () => {
  return {
    type: "CLEAR_SETTINGS",
  };
};

const changePassword = (username, password) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "EDIT_SETTINGS_BUSY",
    });
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "updateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    if (res.status === 200) {
      dispatch({
        type: "PASSWORD_CHANGED",
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Salasana vaihdettu",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jokin meni pieleen. Yritä myöhemmin uudelleen",
          type: "warning",
        },
      });
    }
  };
};

const changeDeviceName = (deviceid, newName) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "NAME_CHANGING",
      payload: {
        device: deviceid,
        name: newName,
      },
    });
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "updateDevice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        name: newName,
        device: deviceid,
      }),
    });
    if (res.status === 200) {
      dispatch({
        type: "NAME_CHANGED",
        payload: {
          device: deviceid,
          name: newName,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Laitenimi vaihdettu",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jotain meni pieleen. Yritä myöhemmin uudelleen.",
          type: "warning",
        },
      });
    }
  };
};

const removeUser = (username) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "REMOVING_USER",
    });
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "deleteUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        username,
      }),
    });
    if (res.status === 200) {
      //let authResult = await res.json();
      dispatch({
        type: "USER_REMOVED",
        payload: {
          username,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Käyttäjä poistettu",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "USER_REMOVE_ERROR",
        payload: {
          error: res.error,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Virhe käyttäjää poistettaessa.",
          type: "warning",
        },
      });
    }
  };
};

const addUser = (username, password) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "EDIT_SETTINGS_BUSY",
    });
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "createUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    if (res.status === 200) {
      //let authResult = await res.json();
      dispatch({
        type: "USER_ADDED",
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Käyttäjä lisätty",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "SETTINGS_ERROR",
        payload: {
          error: res.error,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
          type: "warning",
        },
      });
    }
  };
};
const deleteDeviceFromUser = (deviceid, user) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "DELETE_DEVICE_BUSY",
    });
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "deleteDeviceFromUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        deviceid,
        user,
      }),
    });
    if (res.status === 200) {
      //let authResult = await res.json();
      dispatch({
        type: "DELETE_DEVICE_OK",
        payload: {
          deviceid,
          user,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Laite poistettu.",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "DELETE_DEVICE_ERROR",
        payload: {
          error: res.error,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
          type: "warning",
        },
      });
    }
  };
};

const addDevice = (user, device, deviceType, fromDate, toDate) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "ADDING_DEVICE",
    });
    let { token } = getState().loginState;

    let fromDateSec = null;
    try {
      fromDateSec = moment(fromDate, "D.M.YYYY").format("X");
      if (fromDateSec === "Invalid date") {
        fromDateSec = null;
      }
    } catch (err) {
      //nothing
    }

    let toDateSec = null;
    try {
      toDateSec = moment(toDate, "D.M.YYYY").format("X");
      if (toDateSec === "Invalid date") {
        toDateSec = null;
      }
    } catch (err) {
      //nothing
    }

    let res = await fetch(backendServer + "addDeviceToUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user,
        device,
        deviceType,
        fromDate: fromDateSec,
        toDate: toDateSec,
      }),
    });
    if (res.status === 200) {
      dispatch({
        type: "DEVICE_ADDED",
        payload: {
          user,
          device,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Laite lisätty.",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "DEVICE_ADD_ERROR",
        payload: {
          error: res.error,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
          type: "warning",
        },
      });
    }
  };
};

const getAddedUsers = () => {
  return async (dispatch, getState) => {
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "getAddedUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });
    if (res.status === 200) {
      //let authResult = await res.json();
      dispatch({
        type: "ADDED_USERS",
        payload: {
          users: (await res.json()).users,
        },
      });
    } else {
      dispatch({
        type: "ADDED_USERS_ERROR",
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jokin meni pieleen. Voit kuitenkin muokata omia tietojasi.",
          type: "warning",
        },
      });
    }
  };
};

const updateUserType = (userName, currentType) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "USER_TYPE_CHANGING",
    });
    let { token } = getState().loginState;
    let res = await fetch(backendServer + "updateUserType", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user: userName,
        userType: currentType,
      }),
    });
    if (res.status === 200) {
      //let authResult = await res.json();
      dispatch({
        type: "USER_TYPE_CHANGED",
        payload: {
          newType: (await res.json()).newType,
          userName: userName,
        },
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Käyttäjän tyyppi vaihdettu",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "USER_TYPE_CHANGE_ERROR",
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
          type: "warning",
        },
      });
    }
  };
};

export const SettingAction = {
  setUsername,
  setPassword,
  setPassword2,
  changePassword,
  addUser,
  setError,
  changeDeviceName,
  getAddedUsers,
  updateUserType,
  clearSettings,
  removeUser,
  addDevice,
  deleteDeviceFromUser,
};
