import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  ...state,
});



class ExportModal extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel="Tallenna tiedostoon" ariaHideApp={false}>
        <div className="modal-header">
          <h2>Tallenna tiedostoon</h2>
          {/* <button onClick={this.props.onClose(false)}>Sulje</button> */}
        </div>
        <div className="modal-body">
          <p>Tällä toiminnolla saat kaikkien anturien <b>ihan kaikki</b> arvot CSV-tiedostoon. Kustakin anturista tulee oma tiedostonsa.</p>
          <p>Saat linkin tiedostoon sähköpostitse kustakin anturista erikseen muutaman minuutin päästä. </p>
        </div>
        <div className="modal-footer">
          <button
            className="button button-secondary"
            onClick={this.props.onClose(false)}
          >
            Peruuta
          </button>
          <button
            className="button"
            onClick={this.props.onClose(true)}
          >
            Tallenna
          </button>
        </div>
      </ReactModal>
    );
  }

}
export default connect(mapStateToProps)(ExportModal);
