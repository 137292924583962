import { combineReducers, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { loginReducer } from "../redux/reducers/LoginReducer";
import { dataReducer } from "../redux/reducers/DataReducer";
import { filterReducer } from "../redux/reducers/FilterReducer";
import { settingsReducer } from "../redux/reducers/SettingsReducer";
import { messageReducer } from "../redux/reducers/MessageReducer";
import { recoveryReducer } from "../redux/reducers/RecoveryReducer";
import { createLogger } from "redux-logger";



const rootReducer = combineReducers({
  loginState: loginReducer,
  dataState: dataReducer,
  filterState: filterReducer,
  settingsState: settingsReducer,
  messageState: messageReducer,
  recoveryState: recoveryReducer,
  // any other reducers here
});

let middleware = [thunkMiddleware];
if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    // ...options
  });
    middleware = [...middleware, logger];
}

export const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);
