import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Taustaa extends Component {
  render() {
    return (
      <>
        <h1>Sisäilman mittaus on tärkeää</h1>
        <Helmet>
          <title>Taustaa - mitattu.fi</title>
          <meta
            name="description"
            content="Jatkuva sisäilman mittaus pienentää sairastumisen riskiä"
          />
        </Helmet>
        <p>
          Tiesitkö, että sisäilman lämpötilan lasku 24 °C:sta 21 °C:een vähentää
          riskiä sairastua sisäilmasairauksiin. Kaiken lisäksi lämpötilan lasku
          yhdellä asteella vähentää lämmityskustannuksia 5 %:lla. Seuraamalla
          lämpötilaa voit siis sekä parantaa sisäilman laatua että säästää
          rahaa.
        </p>
        <p>Lue lisää:</p>
        <ul>
          <li>
            <a href="https://www.motiva.fi/koti_ja_asuminen/hyva_arki_kotona/hallitse_huonelampotiloja">
              Hallitse huonelämpötiloja [motiva.fi]{" "}
            </a>
          </li>
        </ul>
        <h2>Paine-ero on merkittävä riski sisäilmalle</h2>
        <p>
          Jos asunto on ylipaineinen, voi sisäilman kosteus tiivistyä
          rakenteisiin. Etenkin talvella riski rakenteisiin jäävälle kosteudelle
          on suuri. Jos rakenteet ovat märät, niissä asustaa taatusti myös
          homeita.
        </p>
        <p>
          Jos asunto on liian alipaineinen, huoneilmaan tulee epäpuhtauksia
          hallitsemattomasti asunnon rakenteista.
        </p>
        <p>
          Paine-erot ovat niin pieniä, että ihminen ei itse aisti niitä. Paras
          tapa selvittää asunnon ilmanvaihdon toimivuus on mitata se
          pitkäkestoisella mittauksella. Paine-eroon vaikuttavat merkittävästi
          ulkoilman lämpötila ja tuuli sekä tietenkin ilmanvaihdon säädöt.{" "}
        </p>
        <p>
          Pidempiaikainen altistuminen homeille voi johtaa pysyvään
          allergisoitumiseen, jolloin ns. normaalistakin sisäilmasta saa
          oireita.
        </p>
        <p>Lue lisää: </p>
        <ul>
          <li>
            <a href="https://yle.fi/uutiset/3-10392991">
              Professori toivoo tiukempaa ilmanvaihdon ohjeistusta: Pari letkua
              ja mittari voivat pelastaa koulut sisäilmaongelmilta [YLE uutiset]
            </a>
          </li>
          <li>
            <a href="https://yle.fi/uutiset/3-7066668">
              Väärin säädetty ilmastointi aiheuttaa isoja ongelmia [YLE uutiset]
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.sisailmayhdistys.fi/Terveelliset-tilat/Kosteusvauriot/Kosteustekninen-toiminta/Ilmavirtaukset-rakennuksessa">
              Perustietoa paine-erosta ja ilmavirtauksista [sisäilmayhdistys]{" "}
            </a>
          </li>
        </ul>
        <h2>Reaaliaikainen seuranta ja selkeät kuvaajat</h2>
        <p>
          Voit seurata asunnon ilmanlaatua kätevästi tietokoneella, kännykällä
          kotoa tai etänä. Näet nykyiset arvot tai voit kätevästi nähdä
          kuvaajana, miten esimerkiksi olohuoneen tai makuuhuoneen
          hiididioksiditasot nousevat ja laskevat. Korjaavat toimenpiteet ovat
          usein varsin helppo tehdä - jätä makkarin ovi raolleen, laske
          termostaatin lämpötilaa. Toisinaan tuloksista selviää isompiakin
          ongelmia - asuntoon on jälkiasennettu poistoilmapuhallin ja asunto on
          liian alipaineinen. Viat on kuitenkin helppo korjata niin pitkään, kun
          niistä selviää rakennuksia korjaamalla.{" "}
        </p>
        <h2>Miksi sisäilman seuranta on ollut minulle tärkeää</h2>
        <p>
          Asuin perheeni kanssa tyytyväisenä -82 rakennetussa rivitalossa.
          Viiden vuoden asumisen jälkeen aloimme epäillä jatkuvan
          sairastelukierteen johtuvan sisäilmaongelmista. Juurisyyksi paljastui
          asuntoyhtiöön jälkiasennetut poistoilmapuhaltimet. Asunnot eivät
          saaneet riittävästi korvausilmaa, jolloin asuntoon tuli kohtuuttoman
          paljon epäpuhdasta ilmaa rakennuksen alapohjasta. Diplomi-insinöörinä
          halusin tietenkin ratkaisun, jolla vastaavat ongelmat pystyttäisiin
          jatkossa ennaltaehkäisemään.
        </p>
        <p>
          Maavaraisen betonilaatan tai valesokkelin alle ei pysty
          korjaustoimenpiteitä helposti tekemään. Suurin osa 1960-2000 Suomeen
          rakennetuista rivi- ja omakotitaloista sisältää sellaisia
          riskirakenteita, joita tulisi asumisterveyden takia jatkuvasti
          seurata. Kerrostaloissa taas suuret korkeuserot tekevät painesuhteiden
          hallinnasta vaikeaa. Jos yhdessäkin asunnossa säädetään
          poistoventtiilin ilmamäärää, saattaa kaikkien muiden asuntojen
          painesuhde vääristyä. Jos esim. oven postiluukusta tulee huomattavia
          määriä ilmaa tai vinkunaa, on se merkki, että painesuhteet eivät ole
          kohdallaan.
        </p>
        <p>
          Toistaiseksi ei ole olemassa luotettavaa tapaa mitata terveydelle
          vaarallisia homepitoisuuksia suoraan sisäilmasta. Homeiden ja muiden
          epäpuhtauksien vaikutusmekanismeja ei vielä tunneta, joten niiden
          mittaaminen ennaltaehkäisevässä seurannassa ei tule kysymykseen.
        </p>
        <p>
          Ennaltaehkäisevissä jatkuvassa mittauksessa toistaiseksi
          kustannustehokkaimmat tavat seurata ilmanvaihdon toimivuutta ovat
          paine-ero, hiilidioksidi, lämpötila ja kosteus. Nykytekniikka ja IoT
          mahdollistaa mittaustulosten siirtämisen verkkoon, jolloin mittarissa
          itsessään ei tarvita paljoa älyä. Jatkuva mittaus on
          kustannustehokasta.{" "}
        </p>
        <h2>Tiedonsiirto (API)</h2>
        <p>
          Jos haluat automatisoida omaa ilmanvaihtoasi mittarin perusteella,
          sekin onnistuu. API-rajapintojen kautta tieto on saatavissa myös omiin
          systeemeihisi helposti.{" "}
        </p>
        <h2>Sisäilmasta muualla</h2>
        <p>
          Jos sisäilma-asiat kiinnostavat, löytyy lisätietoa hyvin netistäkin.
          Tässä muutamia hyviä linkkejä. Muitakin hyviä lähteitä varmasti
          löytyy. Otan mielelläni vastaan myös linkkiehdotuksia.
        </p>
        <ul>
          <li>
            {" "}
            <a href="https://www.sisailmayhdistys.fi">Sisäilmayhdistyksellä </a>
            <span>paljon hyviä sivuja, esim. </span>
            <a href="https://www.sisailmayhdistys.fi/Terveelliset-tilat/Sisailmasto/Sisailman-vaikutukset">
              sisäilman vaikutukset
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.hengitysliitto.fi/fi/sisailma/sisailma-asiat-sisailmaongelmat">
              Hengitysliiton{" "}
            </a>
            <span>sisäilma-asiat</span>
          </li>
          <li>
            {" "}
            <a href="https://hometalkoot.fi/">Hometalkoot</a>
            <span> - paljon tietoa riskirakenteista</span>
          </li>
          <li>
            {" "}
            <a href="https://thl.fi/fi/web/ymparistoterveys/sisailma">THL </a>
            <span>
              - virallista tietoa sisäilmasta, mutta kaikkea ei kai uskalleta
              sanoa ääneen
            </span>
          </li>
          <li>
            {" "}
            <a href="https://homepakolaiset.fi/">Homepakolaiset ry </a>
            <span>
              ajaa etujärjestönä tärkeää asiaa. Kunpa heitä kuunneltaisiin
              paremmin. Jos olet lukenut tätä sivua tänne asti, kannattaa
              ehdottomasti jatkaa lukemista myös tuolla.
            </span>
          </li>
          <li>
            {" "}
            <a href="https://www.theseus.fi/bitstream/handle/10024/794875/Huhtala_Pia.pdf">
              Opinnäytetyö{" "}
            </a>
            <span>
              hiilidioksidipitoisuuksien mittaamisesta ilmanvaihdon riittävyyden
              arvioinnissa.
            </span>
          </li>
        </ul>
      </>
    );
  }
}
