import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Device from "./DeviceBox";
import Chart from "./Chart";
import Filters from "./Filters";
import { Helmet } from "react-helmet";
import "./button-group.scss";
import { connect } from "react-redux";
import { DataAction } from "./redux/actions/DataActions";
import { MessageAction } from "./redux/actions/MessageActions";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  addMessage: (message, type) =>
    dispatch(MessageAction.addMessage(message, type)),
  updateChartData: () => dispatch(DataAction.updateChartData()),
  changeApi: (deviceid, apikey) => dispatch(DataAction.changeApi(deviceid, apikey)),
});

require("moment/locale/fi");

const compareData = (a, b) => {
  if ( a?.name < b?.name ){
    return -1;
  }
  if ( a?.name > b?.name ){
    return 1;
  }
  if ( a?.deviceid < b?.deviceid ){
    return -1;
  }
  if ( a?.deviceid > b?.deviceid ){
    return 1;
  }
  return 0;
}

class Data extends Component {
  //load chart data when coming to the screen for the first time
  componentDidMount() {
    if (!this.props.dataState.data.length) {
      this.props.updateChartData();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.filterState.fromDate !== prevProps.filterState.fromDate ||
      this.props.filterState.toDate !== prevProps.filterState.toDate
    ) {
      this.props.updateChartData();
    }
  }
  render() {
    let { data, busy } = this.props.dataState;
    let { showDetailedValues, sensors } = this.props.filterState;
    let { initializing, token } = this.props.loginState;
    if (!initializing && !token) {
      this.props.addMessage("Ole hyvä ja kirjaudu ensin.", "warning");
      return <Redirect to="/" />;
    }

    if (!data || busy) {
      return <h1>Loading...</h1>;
    }
    data.sort(compareData);
    const Summaries = data.map((item, key) => {
      if (
        typeof item !== "undefined" &&
        item.deviceid &&
        this.props.dataState[item.deviceid] &&
        sensors[item.deviceid]
      ) {
        return (
          <React.Fragment key={key + "frag"}>
            <Device
              data={item}
              rawValues={this.props.dataState[item.deviceid]}
              key={key}
              showCalculated={showDetailedValues}
              changeApi={this.props.changeApi}
            />
            <div className="charts" key={key + "charts"}>
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{mean: NaN, min: 0, max: NaN}}
                title="Teho"
                xName="power"
                unit="W"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{mean: NaN, min: 0, max: NaN}}
                title="Matkamittari"
                xName="odometer"
                unit="km"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{mean: NaN, min: 0, max: NaN}}
                title="CPU"
                xName="cpu"
                unit=""
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{mean: NaN, min: 0, max: NaN}}
                title="Tiedostoja"
                xName="numFiles"
                unit=""
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{mean: NaN, min: 0, max: NaN}}
                title="Muistia jäljellä"
                xName="memFree"
                unit=" MB"
                divider={1024}
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{mean: NaN, min: 0, max: NaN}}
                title="Levytilaa jäljellä"
                xName="freespace"
                unit=" GB"
                divider={1024*1024*1024}
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid].co2}
                title="co2"
                xName="co2"
                unit=" ppm"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid].pressure}
                title={this.props.dataState[item.deviceid].data[0]?.type==='ruuvi'?"Ilmanpaine":"Paine-ero"}
                xName="pressure"
                unit=" Pa"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid].temperature}
                title="Lämpötila"
                xName="temperature"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid].humidity}
                title="Ilmankosteus"
                xName="humidity"
                unit=" %RH"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Compressor speed RPM"]}
                title="Compressor speed RPM"
                xName="Compressor speed RPM"
                unit=" (RPM)"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Brine in"]}
                title="Brine in"
                xName="Brine in"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Brine out"]}
                title="Brine out"
                xName="Brine out"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{}}
                title="Conderser in temperature"
                xName="Conderser in temperature"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={{}}
                title="Conderser out temperature"
                xName="Conderser out temperature"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Discharge pipe temperature"]}
                title="Discharge pipe temperature"
                xName="Discharge pipe temperature"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Outdoor temperature"]}
                title="Outdoor temperature"
                xName="Outdoor temperature"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Tap water top"]}
                title="Tap water top"
                xName="Tap water top"
                unit=" °C"
                sensorName={item}
              />
              <Chart
                data={this.props.dataState[item.deviceid].data}
                average={this.props.dataState[item.deviceid]["Tap water bottom"]}
                title="Tap water bottom"
                xName="Tap water bottom"
                unit=" °C"
                sensorName={item}
              />
            </div>
          </React.Fragment>
        );
      } else {
        if (sensors[item.deviceid]) {
          return <p key={"" + key + key}>Ei dataa valittuna ajanjaksona</p>;
        }
      }
    });

    // console.log('Home', this.props);
    return (
      <>
        <Helmet title="Anturien arvot - mitattu.fi" />
        <div className="datapage">
          <Filters />
          <div className="devices">{Summaries}</div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Data);
