import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { RecoveryActions } from "./redux/actions/RecoveryActions";

import Input from "./components/Input";

import { validateEmail } from "./utils/helperFunctions";

import "./salasananPalautus.css";

const mapDispatchToProps = (dispatch) => ({
  sendEmailToUser: (email) => dispatch(RecoveryActions.sendEmailToUser(email)),
});

class SalasananPalautus extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  render() {
    return (
      <>
        <div className="salasana-page">
          <Helmet
            title="Salasanan palautus - mitattu.fi"
            meta={[
              {
                name: "description",
                content: "Sisäilmamittauksen hinnasto ja yhteydenotto",
              },
            ]}
          />
          <h1>Palauta unohtunut salasana</h1>
          <div className="restore-form">
            Ei hätää. Anna sähköpostiosoite, jolla olet kirjautunut sisään. Saat
            sähköpostiisi ohjeet salasanan palauttamiseksi.
            <Input
              label="Sähköposti"
              id="email"
              type="email"
              onChange={this.onChangeEvent}
            />
            <p style={{ color: "red" }}>{this.state.error}</p>
            <input
              type="submit"
              onClick={this.postForm}
              className="button"
              value="Lähetä"
            />
          </div>
        </div>
      </>
    );
  }

  onChangeEvent = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  postForm = async () => {
    if (!validateEmail(this.state.email)) {
      this.setState({ error: "Tarkista antamasi sähköpostiosoite" });
      return;
    }
    this.props.sendEmailToUser(this.state.email);
    this.props.history.push("/palautetaan/");
  };
}

export default withRouter(connect(null, mapDispatchToProps)(SalasananPalautus));
