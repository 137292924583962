import React, { Component } from "react";
import { connect } from "react-redux";
import { MessageAction } from "./redux/actions/MessageActions";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  closeMessage: () => dispatch(MessageAction.closeMessage()),
});

class Message extends Component {
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.messageState.message !== prevProps.messageState.message &&
      this.props.messageState.message
    ) {
      setTimeout(() => {
        this.props.closeMessage();
      }, this.props.messageState.timeout);
    }
  }

  render() {
    let { message, type, closable } = this.props.messageState;

    if (message) {
      const classForMessage = "message message-" + type;
      return (
        <div className={classForMessage}>
          <span className="message-text">{message}</span>
          {closable ? (
            <div className="close-message">
              <button className="link-button" onClick={this.props.closeMessage}>
                Sulje
              </button>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);
