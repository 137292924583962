import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Rekisteriseloste extends Component {
  render() {
    return (
      <>
        <Helmet title="Tietosuoja- ja rekisteriseloste - mitattu.fi" />
        <div className="registers-page">
          <h1>Tietosuoja- ja rekisteriseloste</h1>
          Henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen
          (GDPR) mukainen rekisteri- ja tietosuojaseloste. Päivitetty 11.3.2024.
          <h2>Rekisterinpitäjä</h2>
          Tmi Petteri Suontama (2384205-9)
          <br />
          Nihtikuja 7B
          <br />
          02630 Espoo
          <h2>Rekisteristä vastaava henkilö</h2>
          Petteri Suontama
          <br />
          petteri.suontama@iki.fi
          <h2>Rekisterin nimi</h2>
          Yrityksen asiakas- ja markkinointirekisteri
          <h2>Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h2>
          <ul>
            <li>
              henkilön suostumus (dokumentoitu, vapaaehtoinen,
              yksilöity,tietoinen ja yksiselitteinen)
            </li>
            <li>sopimus, jossa rekisteröity on osapuolena</li>
            <li>
              rekisterinpitäjän oikeutettu etu (esim.asiakassuhde, jäsenyys).
            </li>
          </ul>
          <h2>Rekisterintietosisältö ja tietolähteet</h2>
          <p>
            Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm.
            www-lomakkeilla lähetetyistä viesteistä, sähköpostitse, puhelimitse,
            sosiaalisen median palvelujen kautta, sopimuksista,
            asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa
            tietojaan.
          </p>
          Käyttäjistä kerätään
          <ul>
            <li> Nimi</li>
            <li> Kotiosoite</li>
            <li> Laskutusosoite</li>
            <li> Sähköposti</li>
            <li> Puhelinnumero</li>
            <li> Tilatut palvelut</li>
            <li> Laskutuksen tilanne</li>
            <li> Rekisteröitymistapa ja rekisteröinnin tekijän tiedot</li>
            <li>
              {" "}
              Mittausantureiden antamat mittausarvot ja niihin liittyvät nimitiedot ja
              tekniset tiedot
            </li>
          </ul>
          <p>
            Palvelun ylläpito-oikeuksilla on mahdollista lisätä uusia käyttäjiä. Ylläpitäjät näkevät itse lisäämiensä käyttäjien sähköpostiosoitteet, sallitut laitteet ja aikarajat, jonka aikana käyttäjät voivat nähdä laitteiden dataa. </p>
          <p>
            Palvelun kehittäjillä on mahdollisuus nähdä näitä samoja tietoja. Käyttäjien salasanaa ei tallenneta selkokielisenä, eikä sitä ole mahdollista saada mistään selville.
          </p>
          <h2>Henkilötietojen säilytysaika</h2>
          Henkilötietoja säilytetään niin kauan kuin niitä on tarpeen säilyttää
          sen tarkoituksen täyttämiseksi, jota varten ne on kerätty tämän
          tietosuojaselosteen mukaisesti, kuitenkin korkeintaan 5 vuotta
          toiminnan päätyttyä. Henkilötietojen säilytyksessä huomioidaan
          passiivisiksi jääneet henkilötiedot, joita poistetaan säännöllisesti.
          <h2>Automaattinen päätöksenteko</h2>
          Asiakkaita ei profiloida automaattisesti.
          <h2>
            Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai
            ETA:n ulkopuolelle
          </h2>
          Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja
          säilytetään kuitenkin pilvipalvelussa (Amazon/Google), joiden
          serverien sijainti voi olla myös EU:n tai ETA:n ulkopuolella.
          <h2>Rekisterinsuojauksen periaatteet</h2>
          Rekisterin käsittelyssä noudatetaan huolellisuutta ja
          tietojärjestelmien avulla käsiteltävät tiedot suojataan
          asianmukaisesti. Kun rekisteritietoja säilytetään
          Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta
          tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä
          huolehtii siitä, että tallennettuja tietoja sekä palvelimien
          käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta
          kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden
          työntekijöidentoimesta, joiden työnkuvaan se kuuluu.
          <h2>Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h2>
          Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa
          rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen
          tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli
          henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin
          oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle ja
          pyynnössä tulee kertoa sähköpostiosoite tai postiosoite, johon
          vastauksen voi toimittaa. Rekisterinpitäjä voi pyytää tarvittaessa
          pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä
          vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa
          (pääsääntöisesti kuukauden kuluessa).
          <h2>Muut henkilötietojen käsittelyyn liittyvät oikeudet</h2>
          Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien
          henkilötietojen poistamiseen rekisteristä (&quot;oikeus tulla
          unohdetuksi&quot;). Niin ikään rekisteröidyillä on muut EU:nyleisen
          tietosuoja-asetuksen mukaiset oikeudetkuten henkilötietojen käsittelyn
          rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää
          kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää
          tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä.
          Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa
          säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
          <h2>Evästeet tai niihin verrattavat tallennustiedot</h2>
          Verkkosivustolla käytetään evästeitä ja selaimen välimuistia. Evästeet ovat pieniä
          käyttäjäkohtaisia tekstitiedostoja, jotka tallentuvat sivustolla
          vierailevan verkkoselaimeen. Palvelin voi myöhemmin lukea selaimeen
          asetetun evästeen ja näin selain voidaan tunnistaa aiemmin sivuillamme
          käyneeksi ja kirjautuneeksi. Sivusto käyttää omia evästeitä kirjautumista varten.
          Kolmansien osapuolten evästeitä ei käytetä.
          <p className="thanks">
            <Link className="button" to="/">
              Etusivulle
            </Link>
          </p>
        </div>
      </>
    );
  }
}
