import React, { Component } from "react";
import Frame from "./Frame";
import LoginScreen from "./LoginScreen";
import { connect } from "react-redux";
import { LoginAction } from "./redux/actions/LoginActions";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(LoginAction.logout()),
});

class WebPages extends Component {
  render() {
    let showLogin = false;
    if (
      this.props.location &&
      this.props.location.pathname.startsWith("/login")
    ) {
      showLogin = true;
    }
    return (
      <>
        {showLogin ? <LoginScreen dismiss={this.toggleLogin} /> : null}
        <div className="content">
          <Frame path={this.props.location.pathname} />
        </div>
      </>
    );
  }

  toggleLogin = () => {
    if (this.props.location.pathname.startsWith("/login")) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/login");
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebPages);
