import { backendServer } from "../../variables";
import moment from "moment";
import { LoginAction } from "./LoginActions";

const setBusyState = (newBusyState, dispatch) => {
  dispatch({
    type: "SET_DATA",
    payload: {
      busy: newBusyState,
    },
  });
};

const toggleDetailedValues = () => {
  return (dispatch, getState) => {
    let { showDetailedValues } = getState().dataState;
    dispatch({
      type: "SET_DATA",
      payload: {
        showDetailedValues: !showDetailedValues,
      },
    });
  };
};

const getDevices = () => {
  return async (dispatch, getState) => {
    let { token } = getState.loginState;
    let res = await fetch(backendServer + "getdevices", {
      method: "GET",
      headers: {
        "x-access-token": token,
      },
    });
    if (res.status === 200) {
      let data = res.json();
      dispatch({
        type: "SET_DEVICES",
        payload: {
          data: data,
        },
      });
    } else {
      if (res.status >= 300 && res.status < 400) {
        dispatch({
          type: "LOGOUT",
        });
      }
    }
  };
};

const updateChart = async (deviceId, dispatch, getState) => {
  let { fromDate, toDate, sensors } = getState().filterState;
  if (sensors[deviceId] === false) {
    return;
  }
  setBusyState(true, dispatch);
  let { token } = getState().loginState;
  if (typeof fromDate === "string") {
    fromDate = new moment(fromDate, "D.M.YYYY HH.mm");
  }
  if (typeof toDate === "string") {
    toDate = new moment(toDate, "D.M.YYYY HH.mm");
  }
  let res = await fetch(backendServer + "getDeviceData", {
    method: "POST",
    "Content-Type": "application/json",
    headers: {
      "x-access-token": token,
    },
    body: JSON.stringify({
      deviceId,
      fromDate: fromDate.valueOf(),
      toDate: toDate.valueOf(),
    }),
  });
  if (res.status === 200) {
    let data = await res.json();
    if (Array.isArray(data)) {
      let co2 = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let humidity = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let humidity2 = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let temperature = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let temperature2 = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let pressure = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let power = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let voltage = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let current = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let compressorspeedRPM
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let brineIn
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let brineOut
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let pipeTemp
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let outdoorTemp
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let waterTopTemp
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      let waterBottomTemp
      = {
        sum: 0,
        min: 10000,
        max: -10000,
        mean: [],
      };
      for (let i = 0; i < data.length; i++) {
        if (data[i].co2 && typeof data[i].co2 === "string") {
          data[i].co2 = parseInt(data[i].co2);
          co2.mean.push(data[i].co2);
          if (data[i].co2 > co2.max) {
            co2.max = data[i].co2;
          }
          if (data[i].co2 < co2.min) {
            co2.min = data[i].co2;
          }
          co2.sum += data[i].co2;
        }
        if (data[i].humidity && typeof data[i].humidity === "string") {
          data[i].humidity = parseInt(data[i].humidity);
          humidity.mean.push(data[i].humidity);
          if (data[i].humidity > humidity.max) {
            humidity.max = data[i].humidity;
          }
          if (data[i].humidity < humidity.min) {
            humidity.min = data[i].humidity;
          }
          humidity.sum += data[i].humidity;
        }
        if (data[i].humidity2 && typeof data[i].humidity2 === "string") {
          data[i].humidity2 = parseInt(data[i].humidity2);
          humidity2.mean.push(data[i].humidity2);
          if (data[i].humidity2 > humidity2.max) {
            humidity2.max = data[i].humidity2;
          }
          if (data[i].humidity2 < humidity2.min) {
            humidity2.min = data[i].humidity2;
          }
          humidity2.sum += data[i].humidity2;
        }
        if (data[i].temperature && typeof data[i].temperature === "string") {
          data[i].temperature = parseFloat(data[i].temperature);
          temperature.mean.push(data[i].temperature);
          if (data[i].temperature > temperature.max) {
            temperature.max = data[i].temperature;
          }
          if (data[i].temperature < temperature.min) {
            temperature.min = data[i].temperature;
          }
          temperature.sum += data[i].temperature;
        }
        if (data[i].temperature2 && typeof data[i].temperature2 === "string") {
          data[i].temperature2 = parseFloat(data[i].temperature2);
          temperature2.mean.push(data[i].temperature2);
          if (data[i].temperature2 > temperature2.max) {
            temperature2.max = data[i].temperature2;
          }
          if (data[i].temperature2 < temperature2.min) {
            temperature2.min = data[i].temperature2;
          }
          temperature2.sum += data[i].temperature2;
        }
        if (data[i].pressure && typeof data[i].pressure === "string") {
          data[i].pressure = parseFloat(data[i].pressure);
          pressure.mean.push(data[i].pressure);
          if (data[i].pressure > pressure.max) {
            pressure.max = data[i].pressure;
          }
          if (data[i].pressure < pressure.min) {
            pressure.min = data[i].pressure;
          }
          pressure.sum += data[i].pressure;
        }
        if (data[i].power && (typeof data[i].power === "string" || typeof data[i].power === 'number')) {
          data[i].power = parseFloat(data[i].power);
          power.mean.push(data[i].power);
          if (data[i].power > power.max) {
            power.max = data[i].power;
          }
          if (data[i].power < power.min) {
            power.min = data[i].power;
          }
          power.sum += data[i].power;
        }
        if (data[i].voltage && (typeof data[i].voltage === "string" || typeof data[i].voltage === 'number')) {
          data[i].voltage = parseFloat(data[i].voltage);
          voltage.mean.push(data[i].voltage);
          if (data[i].voltage > voltage.max) {
            voltage.max = data[i].voltage;
          }
          if (data[i].voltage < voltage.min) {
            voltage.min = data[i].voltage;
          }
          voltage.sum += data[i].voltage;
        }
        if (data[i].current && (typeof data[i].current === "string" || typeof data[i].current === 'number')) {
          data[i].current = parseFloat(data[i].current);
          current.mean.push(data[i].current);
          if (data[i].current > current.max) {
            current.max = data[i].current;
          }
          if (data[i].current < current.min) {
            current.min = data[i].current;
          }
          current.sum += data[i].current;
        }
        if (data[i]["Compressor speed RPM"] && (typeof data[i]["Compressor speed RPM"] === "string" || typeof data[i]["Compressor speed RPM"] === 'number')) {
          data[i]["Compressor speed RPM"] = parseInt(data[i]["Compressor speed RPM"]);
        compressorspeedRPM.mean.push(data[i]["Compressor speed RPM"]);
          if (data[i]["Compressor speed RPM"] > compressorspeedRPM.max) {
            compressorspeedRPM.max = data[i]["Compressor speed RPM"];
          }
          if (data[i]["Compressor speed RPM"] < compressorspeedRPM.min) {
          compressorspeedRPM.min = data[i]["Compressor speed RPM"];
          }
        compressorspeedRPM.sum += data[i]["Compressor speed RPM"];
        }
        if (data[i]["Brine in"] && (typeof data[i]["Brine in"] === "string" || typeof data[i]["Brine in"] === 'number')) {
          data[i]["Brine in"] = parseFloat(data[i]["Brine in"]);
        brineIn.mean.push(data[i]["Brine in"]);
          if (data[i]["Brine in"] > brineIn.max) {
            brineIn.max = data[i]["Brine in"];
          }
          if (data[i]["Brine in"] < brineIn.min) {
          brineIn.min = data[i]["Brine in"];
          }
        brineIn.sum += data[i]["Brine in"];
        }
        if (data[i]["Brine out"] && (typeof data[i]["Brine out"] === "string" || typeof data[i]["Brine out"] === 'number')) {
          data[i]["Brine out"] = parseFloat(data[i]["Brine out"]);
        brineOut.mean.push(data[i]["Brine out"]);
          if (data[i]["Brine out"] > brineOut.max) {
            brineOut.max = data[i]["Brine out"];
          }
          if (data[i]["Brine out"] < brineOut.min) {
          brineOut.min = data[i]["Brine out"];
          }
        brineOut.sum += data[i]["Brine out"];
        }
        if (data[i]["Discharge pipe temperature"] && (typeof data[i]["Discharge pipe temperature"] === "string" || typeof data[i]["Discharge pipe temperature"] === 'number')) {
          data[i]["Discharge pipe temperature"] = parseFloat(data[i]["Discharge pipe temperature"]);
        pipeTemp.mean.push(data[i]["Discharge pipe temperature"]);
          if (data[i]["Discharge pipe temperature"] > pipeTemp.max) {
            pipeTemp.max = data[i]["Discharge pipe temperature"];
          }
          if (data[i]["Discharge pipe temperature"] < pipeTemp.min) {
          pipeTemp.min = data[i]["Discharge pipe temperature"];
          }
        pipeTemp.sum += data[i]["Discharge pipe temperature"];
        }
        if (data[i]["Outdoor temperature"] && (typeof data[i]["Outdoor temperature"] === "string" || typeof data[i]["Outdoor temperature"] === 'number')) {
          data[i]["Outdoor temperature"] = parseFloat(data[i]["Outdoor temperature"]);
        outdoorTemp.mean.push(data[i]["Outdoor temperature"]);
          if (data[i]["Outdoor temperature"] > outdoorTemp.max) {
            outdoorTemp.max = data[i]["Outdoor temperature"];
          }
          if (data[i]["Outdoor temperature"] < outdoorTemp.min) {
          outdoorTemp.min = data[i]["Outdoor temperature"];
          }
        outdoorTemp.sum += data[i]["Outdoor temperature"];
        }

        if (data[i]["Tap water top"] && (typeof data[i]["Tap water top"] === "string" || typeof data[i]["Tap water top"] === 'number')) {
          data[i]["Tap water top"] = parseFloat(data[i]["Tap water top"]);
        waterTopTemp.mean.push(data[i]["Tap water top"]);
          if (data[i]["Tap water top"] > waterTopTemp.max) {
            waterTopTemp.max = data[i]["Tap water top"];
          }
          if (data[i]["Tap water top"] < waterTopTemp.min) {
          waterTopTemp.min = data[i]["Tap water top"];
          }
        waterTopTemp.sum += data[i]["Tap water top"];
        }

        if (data[i]["Tap water bottom"] && (typeof data[i]["Tap water bottom"] === "string" || typeof data[i]["Tap water bottom"] === 'number')) {
          data[i]["Tap water bottom"] = parseFloat(data[i]["Tap water bottom"]);
        waterBottomTemp.mean.push(data[i]["Tap water bottom"]);
          if (data[i]["Tap water bottom"] > waterBottomTemp.max) {
            waterBottomTemp.max = data[i]["Tap water bottom"];
          }
          if (data[i]["Tap water bottom"] < waterBottomTemp.min) {
          waterBottomTemp.min = data[i]["Tap water bottom"];
          }
        waterBottomTemp.sum += data[i]["Tap water bottom"];
        }


      }
      if (data.length > 0) {
        humidity.avg = humidity.sum / data.length;
        humidity2.avg = humidity2.sum / data.length;
        pressure.avg = pressure.sum / data.length;
        temperature.avg = temperature.sum / data.length;
        temperature2.avg = temperature2.sum / data.length;
        co2.avg = co2.sum / data.length;
        power.avg = power.sum / data.length;
        voltage.avg = voltage.sum / data.length;
        current.avg = current.sum / data.length;
        compressorspeedRPM.avg = compressorspeedRPM.sum / data.length;
        brineIn.avg = brineIn.sum / data.length;
        brineOut.avg = brineOut.sum / data.length;
        pipeTemp.avg = pipeTemp.sum / data.length;
        outdoorTemp.avg = outdoorTemp.sum / data.length;
        waterTopTemp.avg = waterTopTemp.sum / data.length;
        waterBottomTemp.avg = waterBottomTemp.sum / data.length;

        //median calculation from
        //https://www.jstips.co/en/javascript/array-average-and-median/
        let lowMiddle, highMiddle;

        humidity.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((humidity.mean.length - 1) / 2);
        highMiddle = Math.ceil((humidity.mean.length - 1) / 2);
        humidity.mean =
          (humidity.mean[lowMiddle] + humidity.mean[highMiddle]) / 2;
        humidity2.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((humidity2.mean.length - 1) / 2);
        highMiddle = Math.ceil((humidity2.mean.length - 1) / 2);
        humidity2.mean =
          (humidity2.mean[lowMiddle] + humidity2.mean[highMiddle]) / 2;
  
        pressure.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((pressure.mean.length - 1) / 2);
        highMiddle = Math.ceil((pressure.mean.length - 1) / 2);
        pressure.mean =
          (pressure.mean[lowMiddle] + pressure.mean[highMiddle]) / 2;

        temperature.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((temperature.mean.length - 1) / 2);
        highMiddle = Math.ceil((temperature.mean.length - 1) / 2);
        temperature.mean =
          (temperature.mean[lowMiddle] + temperature.mean[highMiddle]) / 2;

        temperature2.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((temperature2.mean.length - 1) / 2);
        highMiddle = Math.ceil((temperature2.mean.length - 1) / 2);
        temperature2.mean =
          (temperature2.mean[lowMiddle] + temperature2.mean[highMiddle]) / 2;
  

        co2.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((co2.mean.length - 1) / 2);
        highMiddle = Math.ceil((co2.mean.length - 1) / 2);
        co2.mean = (co2.mean[lowMiddle] + co2.mean[highMiddle]) / 2;

        power.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((power.mean.length - 1) / 2);
        highMiddle = Math.ceil((power.mean.length - 1) / 2);
        power.mean = (power.mean[lowMiddle] + power.mean[highMiddle]) / 2;

        voltage.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((voltage.mean.length - 1) / 2);
        highMiddle = Math.ceil((voltage.mean.length - 1) / 2);
        voltage.mean = (voltage.mean[lowMiddle] + voltage.mean[highMiddle]) / 2;

        current.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((current.mean.length - 1) / 2);
        highMiddle = Math.ceil((current.mean.length - 1) / 2);
        current.mean = (current.mean[lowMiddle] + current.mean[highMiddle]) / 2;

        compressorspeedRPM.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((compressorspeedRPM.mean.length - 1) / 2);
        highMiddle = Math.ceil((compressorspeedRPM.mean.length - 1) / 2);
        compressorspeedRPM.mean = (compressorspeedRPM.mean[lowMiddle] + compressorspeedRPM.mean[highMiddle]) / 2;

        brineIn.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((brineIn.mean.length - 1) / 2);
        highMiddle = Math.ceil((brineIn.mean.length - 1) / 2);
        brineIn.mean = (brineIn.mean[lowMiddle] + brineIn.mean[highMiddle]) / 2;

        brineOut.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((brineOut.mean.length - 1) / 2);
        highMiddle = Math.ceil((brineOut.mean.length - 1) / 2);
        brineOut.mean = (brineOut.mean[lowMiddle] + brineOut.mean[highMiddle]) / 2;

        pipeTemp.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((pipeTemp.mean.length - 1) / 2);
        highMiddle = Math.ceil((pipeTemp.mean.length - 1) / 2);
        pipeTemp.mean = (pipeTemp.mean[lowMiddle] + pipeTemp.mean[highMiddle]) / 2;

        outdoorTemp.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((outdoorTemp.mean.length - 1) / 2);
        highMiddle = Math.ceil((outdoorTemp.mean.length - 1) / 2);
        outdoorTemp.mean = Math.round(((outdoorTemp.mean[lowMiddle] + outdoorTemp.mean[highMiddle]) / 2 + Number.EPSILON) * 100) / 100;

        waterTopTemp.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((waterTopTemp.mean.length - 1) / 2);
        highMiddle = Math.ceil((waterTopTemp.mean.length - 1) / 2);
        waterTopTemp.mean = (waterTopTemp.mean[lowMiddle] + waterTopTemp.mean[highMiddle]) / 2;

        waterBottomTemp.mean.sort((a, b) => a - b);
        lowMiddle = Math.floor((waterBottomTemp.mean.length - 1) / 2);
        highMiddle = Math.ceil((waterBottomTemp.mean.length - 1) / 2);
        waterBottomTemp.mean = (waterBottomTemp.mean[lowMiddle] + waterBottomTemp.mean[highMiddle]) / 2;

      }

      dispatch({
        type: "SET_DATA",
        payload: {
          [deviceId]: {
            data,
            pressure,
            humidity,
            temperature,
            co2,
            voltage,
            current,
            power,
            "Compressor speed RPM": compressorspeedRPM,
            "Brine in": brineIn,
            "Brine out": brineOut,
            "Discharge pipe temperature": pipeTemp,
            "Outdoor temperature": outdoorTemp,
            "Tap water top": waterTopTemp,
            "Tap water bottom": waterBottomTemp,
          },
          busy: false,
        },
      });
    }
  } else {
    dispatch({
      type: "SET_DATA",
      payload: {
        [deviceId]: {
          data: []
        },
        busy: false,
      },
    });
  }
};

const updateChartData = () => {
  return async (dispatch, getState) => {
    let { token } = getState().loginState;
    try {
      let res = await fetch(backendServer + "getlatest", {
        method: "GET",
        headers: {
          "x-access-token": token,
        },
      });
      if (res.status === 200) {
        let data = await res.json();
        for (let i = 0; i < data.length; i++) {
          if (data && data[i] !== null) {
            dispatch({
              type: "ADD_SENSOR_FILTER",
              sensor: data[i].deviceid,
            });
            updateChart(data[i].deviceid, dispatch, getState);
          }
        }
        dispatch({
          type: "SET_DATA",
          payload: {
            data: data,
          },
        });
        /*
          } else {
              const error = new Error(res.error);
              this.setState({ loading: false });
              throw error;
          }
          })
          .catch(err => {
          console.error(err);
          this.setState({ loading: false });
          });
          */
      } else if (res.status === 401) {
        dispatch(LoginAction.logout());
      } else {
        console.log("Error:", res);
      }
    } catch (error) {
      dispatch(LoginAction.logout());
      console.log("Error:", error);
    }
  };
};

const changeApi = (deviceid) => {
  return async (dispatch, getState) => {
    let { token } = getState().loginState;
    try {
      let res = await fetch(backendServer + "changeapi", {
        method: "POST",
        headers: {
          "x-access-token": token,
        },
        body: JSON.stringify({
          deviceid,
        }),    
      });
      if (res.status === 200) {
        let data = await res.json();
          dispatch({
            type: "API_CHANGED",
            payload: {
              deviceid,
              apikey: data.api,
            }
          });
      } else if (res.status === 401) {
        dispatch(LoginAction.logout());
      } else {
        console.log("Error:", res);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
};


const loadMinMax = (deviceId, fromDate, toDate) => {
  return async (dispatch, getState) => {
    if (typeof fromDate === "string") {
      fromDate = new moment(fromDate, "D.M.YYYY HH.mm");
    }
    if (typeof toDate === "string") {
      toDate = new moment(toDate, "D.M.YYYY HH.mm");
    }

    let { token } = getState().loginState;
    try {
      let res = await fetch(backendServer + "getMinMax", {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "x-access-token": token,
        },
        body: JSON.stringify({
          deviceId,
          fromDate: fromDate.valueOf(),
          toDate: toDate.valueOf(),
        }),
      });
      if (res.status === 200) {
        let data = await res.json();
        dispatch({
          type: "SET_MINMAX_DATA",
          payload: {
            deviceId,
            data,
          },
        });
      } else if (res.status === 401) {
        dispatch(LoginAction.logout());
      } else {
        console.log("Error:", res);
      }
    } catch (error) {
      dispatch(LoginAction.logout());
      console.log("Error:", error);
    }
  };
};

export const DataAction = {
  toggleDetailedValues,
  updateChartData,
  getDevices,
  updateChart,
  loadMinMax,
  changeApi,
};
