import React, { Component } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { RecoveryActions } from "./redux/actions/RecoveryActions";

import Input from "./components/Input";

import { validateEmail, parseQuery } from "./utils/helperFunctions";

import "./Palauta.css";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  recoveryChangePassword: (email, password, token) =>
    dispatch(RecoveryActions.recoveryChangePassword(email, password, token)),
  checkRecoveryToken: (user, token) =>
    dispatch(RecoveryActions.checkRecoveryToken(user, token)),
});

class Palauta extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    const query = parseQuery(this.props.location.search);
    if (validateEmail(query.user)) {
      this.setState({ email: query.user, token: query.token });
      if (query.token) {
        this.props.checkRecoveryToken(query.user, query.token);
      } else {
        this.setState({
          error:
            "Salasanan palautuslinkki ei ole kopioitunut kunnolla. Yritä kopioida linkki kokonaan. Jos ongelma jatkuu, ota yhteyttä sähköpostilla.",
        });
      }
    } else {
      this.setState({
        error:
          "Salasanan palautuslinkki ei ole kopioitunut kunnolla. Yritä kopioida linkki kokonaan. Jos ongelma jatkuu, ota yhteyttä sähköpostilla.",
        token: false,
        email: "",
      });
    }
  }

  render() {
    if (this.props.recoveryState.changeSuccess === true) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="palauta-page">
          <Helmet
            title="Salasanan palautus - mitattu.fi"
            meta={[
              {
                name: "description",
                content: "Salasanan palautus unohtuneen tilalle",
              },
            ]}
          />
          <h1>Palauta unohtunut salasana</h1>
          <div className="restore-form">
            Anna uusi salasana
            <Input
              label="Sähköposti"
              id="email"
              type="email"
              disabled="true"
              readOnly={true}
              value={this.state.email}
            />
            <Input
              type="password"
              name="password"
              label="Uusi salasana"
              onChange={this.onChangeEvent}
            />
            <Input
              type="password"
              name="password2"
              label="Uusi salasana uudelleen"
              onChange={this.onChangeEvent}
            />
            <p style={{ color: "red" }}>{this.state.error}</p>
            <p style={{ color: "red" }}>
              {this.props.recoveryState.tokenStatus === "error"
                ? "Virhe verkkoyhteydessä tai muu järjestelmävirhe. Yritä myöhemmin uudelleen."
                : null}
            </p>
            <p style={{ color: "red" }}>
              {this.props.recoveryState.tokenStatus === "invalid"
                ? "Käyttämäsi linkki on virheellinen tai vanhentunut. Tarkista, että kopioit koko linkin."
                : null}
            </p>
            <p style={{ color: "red" }}>
              {this.props.recoveryState.message
                ? this.props.recoveryState.message
                : null}
            </p>
            <input
              type="submit"
              onClick={this.resetPassword}
              className="button"
              disabled={
                !validateEmail(this.state.email) ||
                this.props.recoveryState.tokenStatus === "invalid"
              }
              value="Vaihda salasana"
            />
            <Link to="/">Peruuta</Link>
          </div>
        </div>
      </>
    );
  }

  onChangeEvent = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value, error: "" });
  };

  resetPassword = async () => {
    if (this.state.password !== this.state.password2) {
      this.setState({ error: "Antamasi salasanat eivät täsmää." });
      return;
    }
    if (!this.state.password || this.state.password.length < 4) {
      this.setState({ error: "Anna kelvollinen salasana." });
      return;
    }

    if (!validateEmail(this.state.email)) {
      this.setState({ error: "Tarkista antamasi sähköpostiosoite" });
      return;
    }
    this.props.recoveryChangePassword(
      this.state.email,
      this.state.password,
      this.state.token
    );
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Palauta)
);
