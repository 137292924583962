import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { LoginAction } from "./redux/actions/LoginActions";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(LoginAction.logout()),
});

class LogoutScreen extends Component {
  componentDidMount() {
    localStorage.removeItem("token");
    if (this.props) {
      this.props.logout();
    }
  }

  render() {
    let { token } = this.props.loginState;

    if (token) {
      return null;
    }
    return <Redirect to="/" />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutScreen);
