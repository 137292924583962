import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Kiitos extends Component {
  render() {
    return (
      <>
        <Helmet title="Kiitos yhteydenotostasi - mitattu.fi" />
        <h1>Kiitos yhteydenotostasi!</h1>
        <p>Jos jätit yhteystietosi, niin palaan asiaan piakkoin.</p>
        <p className="thanks">
          <Link className="button" to="/">
            Etusivulle
          </Link>
        </p>
      </>
    );
  }
}
