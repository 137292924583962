import React, { Component } from "react";
import { Link } from "react-router-dom";
import Etusivu from "./Etusivu";
import Taustaa from "./Taustaa";
import Yhteystiedot from "./Yhteystiedot";
import Kiitos from "./Kiitos";
import Rekisteriseloste from "./Rekisteriseloste";
import SalasananPalautus from "./salasananPalautus";
import SalasanaaPalautetaan from "./SalasanaaPalautetaan";
import Palauta from "./Palauta";
import petteri from "./petteri.jpg";

export default class Frame extends Component {
  render() {
    return (
      <>
        {this.props.path.length === 1 ? <Etusivu /> : null}
        {this.props.path.startsWith("/taustaa") ? <Taustaa /> : null}
        {this.props.path.startsWith("/yhteydenotto") ? <Yhteystiedot /> : null}
        {this.props.path.startsWith("/kiitos") ? <Kiitos /> : null}
        {this.props.path.startsWith("/palauta") ? <Palauta /> : null}
        {this.props.path.startsWith("/palautetaan") ? (
          <SalasanaaPalautetaan />
        ) : null}
        {this.props.path.startsWith("/salasanan-palautus") ? (
          <SalasananPalautus />
        ) : null}
        {this.props.path.startsWith("/rekisteriseloste") ? (
          <Rekisteriseloste />
        ) : null}
        <footer>
          <h2>Ota yhteyttä</h2>
          <div className="footer-content">
            <div className="contact">
              <p>Petteri Suontama</p>
              <p>petteri.suontama@iki.fi</p>
              <p>044-321 6929</p>
              {this.props.path.startsWith("/yhteydenotto") ? null : (
                <p>
                  <Link className="button" to="/yhteydenotto/">
                    Ota yhteyttä
                  </Link>
                </p>
              )}
            </div>
            <p className="bottom-image">
              <img src={petteri} alt="Kuva Petteristä" />
            </p>
          </div>
          <hr />
          <div className="registers">
            <Link to="/rekisteriseloste/">Tietosuoja- ja rekisteriseloste</Link>
          </div>
        </footer>
      </>
    );
  }
}
