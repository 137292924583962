import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as Lock } from "../images/lock.svg";
import { ReactComponent as LockOpen } from "../images/lock-open.svg";

import "./LockIcon.css";
import UserNavi from "./UserNavi";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = () => ({});

class LockIcon extends Component {
  constructor() {
    super();
    this.state = {
      userNaviOpen: false,
    };
  }
  naviClicked = () => {
    if (this.props.loginState.token) {
      this.setState({ userNaviOpen: !this.state.userNaviOpen });
    } else {
      this.props.history.push("/login");
    }
  };

  hideNavi = () => {
    this.setState({ userNaviOpen: false });
  };

  render() {
    const logged = this.props.loginState.token;

    return (
      <li className="lock">
        <button
          id="login-btn"
          aria-label="Avaa kirjautumisvalikko"
          onClick={this.naviClicked}
          title="Kirjaudu sisään"
        >
          {logged ? <LockOpen /> : <Lock />}
        </button>
        {this.state.userNaviOpen ? <UserNavi hideNavi={this.hideNavi} /> : null}
      </li>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LockIcon)
);
