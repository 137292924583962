import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "chartjs-adapter-date-fns";
import { connect } from "react-redux";
import { DataAction } from "./redux/actions/DataActions";
import { MessageAction } from "./redux/actions/MessageActions";
import { Line } from "react-chartjs-2";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  loadMinMax: (deviceId, fromDate, toDate) => dispatch(DataAction.loadMinMax(deviceId, fromDate, toDate)),
  addMessage: (message, type) =>
    dispatch(MessageAction.addMessage(message, type)),
});

const options = {
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day",
        displayFormats: {
          day: "d.M.yyyy",
        },
      },
    },
  },
};

class MinMaxChart extends Component {
  componentDidMount() {
    this.props.loadMinMax(this.getDeviceId(), this.getFromDate(), this.getToDate());
  }
  componentDidUpdate(prevProps) {
    if (this.getFromDate() !== prevProps.fromDate || this.getToDate() !== prevProps.toDate)
    this.props.loadMinMax(this.getDeviceId(), this.getFromDate(), this.getToDate());
  }

  getDeviceId() {
    let deviceId = this.props.deviceId + "";
    if (!deviceId || deviceId.length < 2) {
      deviceId = "F8:38:FB:48:99:4B";
    }
    return deviceId;
  }
  getFromDate() {

    return this.props.fromDate;
  }
  getToDate() {
    return this.props.toDate;
  }

  render() {
    const chartData = this.props.dataState?.[this.getDeviceId()]?.minmaxData;
    const measurementType = this.props.dataType;
    let dataMean = [];
    let dataMin = [];
    let dataMax = [];
    let labels = [];
    if (!chartData) {
      return null;
    }
    chartData.forEach((dataElement) => {
      dataMean.push(dataElement[measurementType + "Mean"]);
      dataMin.push(dataElement[measurementType + "Min"]);
      dataMax.push(dataElement[measurementType + "Max"]);
      //timestamp is just after mid night. Move it 15 min back to get the right date
      labels.push(Number.parseInt(dataElement.timestamp) - 1000000);
      // labels.push(new Date(Number.parseInt(dataElement.timestamp) - 1000000));
      // const date = new Date(Number.parseInt(dataElement.timestamp) - 1000000);
      // const dateStr = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
      // labels.push(dateStr);
    });
    const data = {
      labels,
      datasets: [
        {
          label: measurementType,
          data: dataMean,
          fill: false,
          backgroundColor: "rgb(0, 99, 132)",
          borderColor: "rgba(0, 99, 132, 0.2)",
          pointRadius: 1,
        },
        {
          label: "",
          data: dataMin,
          fill: false,
          showLine: false,
          pointRadius: 0,
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderColor: "rgba(255, 255, 255, 1)",
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        {
          label: measurementType + " vaihteluväli",
          data: dataMax,
          fill: "-1",
          backgroundColor: "rgba(99, 99, 99, 0.2)",
          borderColor: "rgba(255, 255, 132, 0.2)",
          showLine: false,
          pointRadius: 0,
        },
      ],
    };

    // if (!username && !initializing) {
    //   this.props.addMessage('Ole hyvä ja kirjaudu ensin.', 'warning');
    //   return <Redirect to="/login" />;
    // }

    return (
      <>
        <Helmet title="Min-Max-kuvaaja - mitattu.fi" />
        <Line data={data} options={options} />
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MinMaxChart);
