import React, { Component } from "react";
import { connect } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { round } from "./utils";
import { ReactComponent as XlsxIcon } from "./excel.svg";
import { ReactComponent as ApiIcon } from "./images/qr_code.svg";
import { ReactComponent as EditIcon } from "./images/edit.svg";
import { ReactComponent as HideIcon } from "./images/visibility_off.svg";
import CurrentValueModal from "./CurrentValueModal";
import { MessageAction } from "./redux/actions/MessageActions";
import { SettingAction } from "./redux/actions/SettingActions";
import { FilterAction } from "./redux/actions/FilterActions";

require("moment/locale/fi");

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  addMessage: (message, type) =>
    dispatch(MessageAction.addMessage(message, type)),
  changeDeviceName: (deviceid, currentName) => {
      return () => {
        const enteredName = prompt("Anna anturille nimi", currentName);
        if (enteredName && enteredName.length > 0) {
          dispatch(SettingAction.changeDeviceName(deviceid, enteredName));
        }
      };
    },
    toggleSensorFilter: (sensor) => {
      return () => {
        dispatch(FilterAction.toggleSensorFilter(sensor));
      }
    },
      
});

class Device extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    if (
      this.props &&
      this.props.data &&
      this.props.data.deviceid &&
      this.props.rawValues
    ) {
      let lastMeasurement = moment(this.props.data.timestamp, "x").format(
        "HH:mm"
      );
      //18000000 == 5 hours
      if (
        new Date().valueOf() - parseInt(this.props.data.timestamp) >
        18000000
      ) {
        lastMeasurement = moment(this.props.data.timestamp, "x").format(
          "D.M.YYYY HH:mm"
        );
      }
      if (lastMeasurement == "Invalid date") {
        lastMeasurement = "!! Kytke anturi päälle !!"
      }
      let mem = false;
      let cpu;
      let numFiles;
      let freeSpace;
      //check if the user is allowed to see the data
      let dataUntil = this.props.data.until;
      if (dataUntil > 0) {
        if (dataUntil < 15719230590) {
          dataUntil *= 1000;
        }
        if (Date.now() > dataUntil) {

          return (
            <>
              <CurrentValueModal
                isOpen={this.state.currentValueModalOpen}
                onClose={() => () => this.setState({currentValueModalOpen: false})}
                deviceid={this.props.data.deviceid}
                apikey={this.props.data.api}
                changeApi={this.props.changeApi}
            />

              <div className="device-titles">
                <h2>{this.props.data.name || this.props.data.deviceid}</h2>
                <button className="btn-save" onClick={() => this.setState({currentValueModalOpen: true})}>
                  <ApiIcon />
                  Seuranta
                </button>
              </div>
              <div className="notification-outdated">
                <span className="message-text">Anturin vuosimaksut ovat erääntyneet. Ota yhteyttä ylläpitoon.</span>
              </div>


            </>

          )
        }
      }

      if (this.props.data.type === 'nextcloud') {
        
        mem = (this.props.data.memTotal-this.props.data.memFree)/this.props.data.memTotal * 100;
        cpu = this.props.data.cpu;
        freeSpace = round(this.props.data.freespace / 1024 / 1024 / 1024, 1) + " GB";
        numFiles = this.props.data.numFiles;

      }
      return (
        <>
          <CurrentValueModal
            isOpen={this.state.currentValueModalOpen}
            onClose={() => () => this.setState({currentValueModalOpen: false})}
            deviceid={this.props.data.deviceid}
            apikey={this.props.data.api}
            changeApi={this.props.changeApi}
          />

          <div className="device-titles">
            <h2>{this.props.data.name || this.props.data.deviceid}</h2>
            <button className="btn-save" onClick={this.props.changeDeviceName(this.props.data.deviceid, this.props.data.name)}>
              <span className="save-CSV" />
              <EditIcon />
              Nimeä
            </button>
            <button className="btn-save" onClick={this.props.toggleSensorFilter(this.props.data.deviceid)}>
              <span className="save-CSV" />
              <HideIcon />
              Piilota
            </button>
            <button className="btn-save" onClick={this.saveCSV}>
              <span className="save-CSV" />
              <XlsxIcon />
              Tallenna data
            </button>
            <button className="btn-save" onClick={() => this.setState({currentValueModalOpen: true})}>
              <ApiIcon />
              Seuranta
            </button>
          </div>
          <div className="device-data">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {this.props.showCalculated ? <th>Minimi</th> : null}
                  {this.props.showCalculated ? <th>Mediaani</th> : null}
                  {this.props.showCalculated ? <th>Maksimi</th> : null}
                  <th>
                    {this.props.showCalculated ? <>Viimeisin arvo</> : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.co2 ? (
                  <tr>
                    <th>
                      CO2 <span className="data-unit">(ppm)</span>
                    </th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues.co2.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues.co2.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues.co2.max}</td>
                    ) : null}
                    <td>{this.props.data.co2}</td>
                  </tr>
                ) : null}
                {this.props.data.pressure ? (
                  <tr>
                    <th>
                      {this.props.data.type==='ruuvi'?"Ilmanpaine":"Paine-ero"}
                      <span className="data-unit">(Pa)</span>
                    </th>
                    {this.props.showCalculated ? (
                      <td>{round(this.props.rawValues.pressure.min, 1)}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{round(this.props.rawValues.pressure.mean, 1)}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{round(this.props.rawValues.pressure.max, 1)}</td>
                    ) : null}
                    <td>{round(this.props.data.pressure, 1)}</td>
                  </tr>
                ) : null}
                {this.props.data.temperature ? (
                  <tr>
                    <th>
                      Lämpötila <span className="data-unit">(°C)</span>
                    </th>
                    {this.props.showCalculated ? (
                      <td>{round(this.props.rawValues.temperature.min, 1)}
                          {this.props.data.temperature2?<span>/{round(this.props.rawValues?.temperature2?.min, 1)}</span>:null}
                      </td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{round(this.props.rawValues.temperature.mean, 1)}
                          {this.props.data.temperature2?<span>/{round(this.props.rawValues?.temperature2?.mean, 1)}</span>:null}
                      </td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{round(this.props.rawValues.temperature.max, 1)}
                          {this.props.data.temperature2?<span>/{round(this.props.rawValues?.temperature2?.max, 1)}</span>:null}
                      </td>
                    ) : null}
                    <td>{round(this.props.data.temperature, 1)}
                      {this.props.data.temperature2?<span>/{round(this.props.data.temperature2, 1)}</span>:null}
                    </td>
                  </tr>
                ) : null}
                {this.props.data.humidity ? (
                  <tr>
                    <th>
                      Kosteus <span className="data-unit">(%RH)</span>
                    </th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues.humidity.min}
                          {this.props.data.humidity2?<span>/{this.props.rawValues?.humidity2?.min}</span>:null}
                      </td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues.humidity.mean}
                          {this.props.data.humidity2?<span>/{this.props.rawValues?.humidity2?.mean}</span>:null}
                      </td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues.humidity.max}
                          {this.props.data.humidity2?<span>/{this.props.rawValues?.humidity2?.max}</span>:null}
                      </td>
                    ) : null}
                    <td>{this.props.data.humidity}
                      {this.props.data.humidity2?<span>/{this.props.data.humidity2}</span>:null}
                    </td>
                  </tr>
                ) : null}
                {this.props.data.odometer ? (
                  <tr>
                    <th>
                      Matkamittari <span className="data-unit">(km)</span>
                    </th>
                    {this.props.showCalculated ? (
                      <td>
                      </td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>
                      </td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>
                      </td>
                    ) : null}
                    <td>{this.props.data.odometer}
                    </td>
                  </tr>
                ) : null}
                {mem?(
                  <>
                    <tr>
                      <th>Muistia käytössä</th>
                      <td>{round(mem,1)}%</td>
                    </tr>
                    <tr>
                      <th>CPU-kuorma</th>
                      <td>{round(cpu,1)}</td>
                    </tr>
                    <tr>
                      <th>Tiedostoja</th>
                      <td>{numFiles}</td>
                    </tr>
                    <tr>
                      <th>Levytilaa jäljellä</th>
                      <td>{freeSpace}</td>
                    </tr>
                  </>
            ):null}

            {this.props.data.type === "tasmotaplug" ? (
                <>
                  <tr>
                    <th>
                      Teho <span className="data-unit">(W)</span>
                    </th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues?.power?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues?.power?.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues?.power?.max}</td>
                    ) : null}
                    <td>{this.props.data.power}</td>
                  </tr>
                  <tr>
                    <th>
                      Kulutus tänään <span className="data-unit">(kWh)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td></td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td></td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td></td>
                        ) : null}
                        <td>{this.props.data.today}</td>
                      </tr>
                      <tr>
                        <th>
                          Kulutus eilen <span className="data-unit">(kWh)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td></td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td></td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td></td>
                        ) : null}
                        <td>{this.props.data.yesterday}</td>
                      </tr>
                    </>
                  ):null}


                {this.props.showCalculated ? (
                  <>
                    {this.props.data.type === "tasmotaplug" ? (
                      <>
                      <tr>
                        <th>
                          Virta <span className="data-unit">(A)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.current?.min}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.current?.mean}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.current?.max}</td>
                        ) : null}
                        <td>{this.props.data.current}</td>
                      </tr>
                      <tr>
                        <th>
                          Vaihesiirto <span className="data-unit">(φ)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.factor?.min}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.factor?.mean}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.factor?.max}</td>
                        ) : null}
                        <td>{this.props.data.factor}</td>
                      </tr>
                      <tr>
                        <th>
                          Loisteho <span className="data-unit">(var)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.reactivePower?.min}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.reactivePower?.mean}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.reactivePower?.max}</td>
                        ) : null}
                        <td>{this.props.data.reactivePower}</td>
                      </tr>
                      <tr>
                        <th>
                          Näennäisteho <span className="data-unit">(VA)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.apparentPower?.min}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.apparentPower?.mean}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.apparentPower?.max}</td>
                        ) : null}
                        <td>{this.props.data.apparentPower}</td>
                      </tr>
                      <tr>
                        <th>
                          Jännite <span className="data-unit">(V)</span>
                        </th>
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.voltage?.min}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.voltage?.mean}</td>
                        ) : null}
                        {this.props.showCalculated ? (
                          <td>{this.props.rawValues?.voltage?.max}</td>
                        ) : null}
                        <td>{this.props.data.voltage}</td>
                      </tr>
                      </>
                    ) : null}
                    <tr>
                      <th> Anturin ID</th>
                      <td>{this.props.data.deviceid}</td>
                    </tr>
                  </>
                ) : null}
                {this.props.data.type === "Calibra"?
                <>
                  <tr>
                    <th>Compressor speed RPM</th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Compressor speed RPM"]?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Compressor speed RPM"]?.avg} (keskiarvo)</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Compressor speed RPM"]?.max}</td>
                    ) : null}
                    <td>{this.props.data['Compressor speed RPM']}</td>
                  </tr><tr>
                    <th>Brine in</th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Brine in"]?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Brine in"]?.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Brine in"]?.max}</td>
                    ) : null}
                    <td>{this.props.data['Brine in']}</td>
                  </tr><tr>
                    <th>Brine out</th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Brine in"]?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Brine in"]?.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Brine in"]?.max}</td>
                    ) : null}
                    <td>{this.props.data['Brine out']}</td>
                  </tr><tr>
                    <th>Tap water top</th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Tap water top"]?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Tap water top"]?.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Tap water top"]?.max}</td>
                    ) : null}
                    <td>{this.props.data['Tap water top']}</td>
                  </tr><tr>
                    <th>Tap water bottom</th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Tap water bottom"]?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Tap water bottom"]?.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Tap water bottom"]?.max}</td>
                    ) : null}
                    <td>{this.props.data['Tap water bottom']}</td>
                  </tr><tr>
                    <th>Condenser in temperature</th>
                    <td>{this.props.data['Condenser in temperature']}</td>
                  </tr><tr>
                    <th>Condenser out temperature</th>
                    <td>{this.props.data['Condenser out temperature']}</td>
                  </tr><tr>
                    <th>Outdoor temperature</th>
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Outdoor temperature"]?.min}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Outdoor temperature"]?.mean}</td>
                    ) : null}
                    {this.props.showCalculated ? (
                      <td>{this.props.rawValues["Outdoor temperature"]?.max}</td>
                    ) : null}
                    <td>{this.props.data['Outdoor temperature']}</td>
                  </tr>
                  </>:null}
                <tr>
                  <th className="device-date">Viimeisin mittaus</th>
                  <td className="device-date">{lastMeasurement}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  saveCSV = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const strippedData = [];
    this.props.rawValues.data.forEach((elem) => {
      /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
      const time = moment(elem.timestamp, "x").format();
      const { payload, deviceid, type, timestamp, ...rowTemp } = elem;
      //recreated for correct column order
      const row = {
        time,
        ...rowTemp
      };
      strippedData.push(row);
    });

    const ws = XLSX.utils.json_to_sheet(strippedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      this.props.data.name || this.props.data.deviceid + ".xlsx"
    );
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Device);
