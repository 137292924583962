import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import moment from "moment";

const mapStateToProps = (state) => ({
  ...state,
});

require("moment/locale/fi");

class AttachDeviceModal extends Component {
  constructor() {
    super();

    this.state = {
      fromDate: moment().format("D.M.YYYY"),
      toDate: "",
    };
  }

  render() {
    let data = this.props.dataState.data;
    let alreadyAddedDevices = [];
    let user = this.props.settingsState.users.find(
      (element) => element.username === this.props.isOpen
    );

    if (user) {
      alreadyAddedDevices = user.devices;
    }

    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel="Lisää anturi">
        <div className="modal-header">
          <h2>Lisää anturi</h2>
          <button onClick={this.props.onClose(false)}>Sulje</button>
        </div>
        <div className="modal-body">
          <label>
            Lisää anturi
            <select onChange={this.selectSensor}>
              <option value="">Valitse...</option>
              {data && data.length
                ? data.map((sensor) => {
                    if (
                      alreadyAddedDevices.findIndex(
                        (element) => element.deviceid === sensor.deviceid
                      ) == -1
                    ) {
                      return (
                        <option key={sensor.deviceid} value={sensor.deviceid}>
                          {sensor.name}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </select>
          </label>
          <label className="label-title">Rajoita aikavälille</label>
          <label>
            Anturi näkyy alkaen
            <Datetime
              closeOnSelect={true}
              dateFormat="D.M.YYYY"
              timeFormat={false}
              value={this.state.fromDate}
              onChange={this.setFromDate}
            />
            <span className="value-hint">
              (pp.kk.vvvv tai tyhjä, jos ei rajoitusta)
            </span>
          </label>
          <label>
            Anturi poistuu
            <Datetime
              closeOnSelect={true}
              dateFormat="D.M.YYYY"
              timeFormat={false}
              value={this.state.toDate}
              onChange={this.setToDate}
            />
            <span className="value-hint">
              (pp.kk.vvvv tai tyhjä, jos ei rajoitusta)
            </span>
          </label>
        </div>
        <div className="modal-footer">
          <button
            className="button button-secondary"
            onClick={this.props.onClose(false)}
          >
            Peruuta
          </button>
          <button
            className="button"
            onClick={this.props.onClose(
              true,
              this.state.sensor,
              this.state.deviceType,
              this.state.fromDate.valueOf(),
              this.state.toDate.valueOf()
            )}
          >
            Lisää
          </button>
        </div>
      </ReactModal>
    );
  }

  setFromDate = (date) => {
    if (moment.isMoment(date)) {
      this.setState({ fromDate: date.format("D.M.YYYY") });
    } else {
      this.setState({ fromDate: date });
    }
  };
  setToDate = (date) => {
    if (moment.isMoment(date)) {
      this.setState({ toDate: date.format("D.M.YYYY") });
    } else {
      this.setState({ toDate: date });
    }
  };

  selectSensor = (evt) => {
    const device = this.props.dataState.data.find(
      (device) => device.deviceid === evt.target.value
    );
    let type = "";
    if (device) {
      type = device.type;
    }
    console.log("type:", type);
    this.setState({ sensor: evt.target.value, deviceType: type });
  };
}
export default connect(mapStateToProps)(AttachDeviceModal);
