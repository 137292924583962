import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Etusivu extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Etusivu - mitattu.fi</title>
          <meta
            name="description"
            content="Sisäilman mittaus ja seuranta: paine-ero, hiilidioksidi, VOC, lämpötila, ilmankosteus, pienhiukkaset"
          />
        </Helmet>
        <h1>Sisäilman laadun seurannalla tiedät, mitä hengität</h1>
        <p>
          Seuraa ilmanvaihdon laatua reaaliajassa. Saat tiedot kätevästi suoraan
          kännykkääsi tai tietokoneelle. Hinta ostaessasi laitteen on vain 3,5
          €/kk. Vuokraan laitteita myös esimerkiksi viikon mittaisiksi ajoiksi.
        </p>
        <h2>Säästä lämmityskuluissa</h2>
        <p>
          Seuraa sisäilman lämpötilaa ja vertaa sitä ulkoilman lämpötilaan.
          Säädä lämpötila juuri sinulle mieluiseksi - älä liian korkeaksi.
          Samalla riski sairastua sisäilman takia pienenee.
        </p>
        <div className="tarjous">
          <Link className="button" to="/yhteydenotto/">
            Pyydä tarjous
          </Link>
          <p className="offer">
            Mittarin hinta 70€. Tai vuokraa laite vain viikoksi 25 € hintaan.
          </p>
        </div>
        <h2>Hiilidioksidi kertoo ilmanvaihdon riittävyydestä</h2>
        <p>
          Tunnetko itsesi väsyneeksi aamulla? Onko pidempiaikainen keskittyminen
          vaikeaa? Pysyykö asuntosi tai työtilasi hiilidioksiditaso riittävän
          alhaalla?{" "}
        </p>
        <p>Selvitä hiilidioksidin määrä mittaamalla se. </p>
        <div className="tarjous">
          <Link className="button" to="/yhteydenotto/">
            Hinnasto
          </Link>
          <p className="offer">
            Mittarin hinta 165€. Tai vuokraa laite vain viikoksi 50€ hintaan.
          </p>
        </div>
        <h2>Paine-ero on yksi tärkeimpiä mittareita</h2>
        <p>
          Sopiva paine-ero on yksi tärkeimpiä hyvän sisäilman edellytyksiä. Sen
          jatkuva seuraaminen on tärkeää niin koti-, koulu- kuin
          toimistoympäristössä.
        </p>
        <p>
          Paine-ero vaihtelee esim. tuulen suunnan, avoimen ikkunan tai
          pölyyntyvien ilmastointikanavien takia. Paine-eroa ei ihminen huomaa,
          joten jatkuva seuranta on ainoa vaihtoehto tietää, että tilan säädöt
          ovat oikein - vielä vuosia rakentamisen jälkeenkin.
        </p>
        <p>
          Liian suuri alipaine johtaa epäpuhtauksien kulkeutumiseen rakenteiden
          kautta sisäilmaan. Ylipaine taas painaa sisäilman kosteuden
          rakenteisiin.
        </p>
        <p>
          Suosittelen paine-eromittausta pitkänä seurantana. Näet tilanteen
          kätevästi kuvaajasta.
        </p>
        <div className="tarjous">
          <Link className="button" to="/yhteydenotto/">
            Pyydä tarjous
          </Link>
          <p className="offer">
            Mittarin hinta 165€. Tai vuokraa laite vain viikoksi 50€ hintaan.
          </p>
        </div>
        <h2>Kuiva ilma kuivattaa limakalvotkin</h2>
        <p>
          Kutittaako nenää, onko limakalvot kuivana? Voisiko syynä olla liian
          kuiva sisäilma? Seuraa sisäilman kosteutta reaaliajassa.
        </p>
        <div className="tarjous">
          <Link className="button" to="/yhteydenotto/">
            Hinnasto
          </Link>
          <p className="offer">
            Tyypillisesti osana muita mittareita - kaupan päälle.
          </p>
        </div>
        <h2>Rakennusmateriaaleista VOC-päästöjä</h2>
        <p>
          Oletko muuttanut hiljattain uuteen asuntoon? Tuleeko materiaaleista
          VOC-päästöjä? Erityisesti, jos kosteutta pääsee rakenteisiin esim.
          alapohjan kautta, voi VOC-päästöt nousta.
        </p>
        <div className="tarjous">
          <Link className="button" to="/yhteydenotto/">
            Pyydä tarjous
          </Link>
          <p className="offer">
            Vuokraa laite viikoksi 50€ hintaan tai osta omaksi.
          </p>
        </div>
        <h2>Kiinnostaako pienhiukkaset?</h2>
        <p>
          Pienet partikkelit tunkeutuvat syvälle keuhoihin. Niitä ei haista,
          mutta pidemmän päälle ne eivät tee hyvää. Selvitä PM2.5 ja PM10
          pitoisuudet.
        </p>
        <div className="tarjous">
          <Link className="button" to="/yhteydenotto/">
            Hinnasto
          </Link>
          <p className="offer">
            Vuokraa laite viikoksi 50€ hintaan tai osta omaksi.
          </p>
        </div>
      </>
    );
  }
}
