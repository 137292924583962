import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { SettingAction } from "./redux/actions/SettingActions";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  clearSettings: () => dispatch(SettingAction.clearSettings()),
  setUsername: (event) =>
    dispatch(SettingAction.setUsername(event.target.value)),
  addUser: (username, password) =>
    dispatch(SettingAction.addUser(username, password)),
});

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      error: "",
      userNameToAdd: "",
    };
  }

  setValue = (field) => {
    return (evt) => {
      let value = evt.target.value;
      this.setState({ [field]: value });
    };
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.settingsState.done !== prevProps.settingsState.done &&
      this.props.settingsState.done
    ) {
      this.props.clearSettings();
    }
  }

  render() {
    const { done, busy, error } = this.props.settingsState;
    const { username, userType } = this.props.loginState;
    if (done) {
      return <Redirect to="/settings" />;
    }
    if (!username || !(userType === "admin" || userType === "super")) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        {error ? <p className="error">{error}</p> : null}
        {this.state.error ? <p className="error">{this.state.error}</p> : null}
        <form autoComplete="off">
          <label>Sähköposti</label>
          <input
            type="email"
            value={this.state.userNameToAdd}
            name="username"
            onChange={this.setValue("userNameToAdd")}
            autoComplete="off"
          />
          <label>Salasana</label>
          <input
            type="password"
            name="password"
            value={this.state.password}
            autoComplete="new-password"
            onChange={this.setValue("password")}
          />
          <label>Salasana uudelleen</label>
          <input
            type="password"
            value={this.state.password2}
            onChange={this.setValue("password2")}
            autoComplete="off"
          />
          <br />
          <input
            type="button"
            className="button"
            disabled={busy}
            value="Lisää"
            onClick={this.addUser}
          />
          <Link to="/settings" className="button">
            Peruuta
          </Link>
        </form>
      </>
    );
  }

  addUser = () => {
    const { userNameToAdd, password2, password } = this.state;
    if (password !== password2) {
      this.setState({ error: "Salasanat eivät täsmää" });
      return;
    }
    if (password.length < 3) {
      this.setState({ error: "Anna kelvollinen salasana" });
      return;
    }
    this.props.addUser(userNameToAdd, password);
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
