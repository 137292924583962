import React from "react";

export default function input(props) {
  return (
    <label>
      <span>{props.label}</span>
      <input
        type={props.type}
        id={props.id}
        name={props.name || props.id}
        onChange={props.onChange}
        className={props.className}
        value={props.value}
      />
    </label>
  );
}
