import React, { Component } from "react";
import { connect } from "react-redux";
import Logo from "./Logo";
import Navigation from "./components/Navigation";

import "./Header.css";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = () => ({});

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Logo />
        <div className="main-title">
          <h1>Mitattu</h1>
          <div className="slogan">Terveellistä sisäilmaa</div>
        </div>
        <Navigation />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
