import React, { Component } from "react";
import Datetime from "react-datetime";
import "./button-group.scss";
import "./filter.scss";
import { connect } from "react-redux";
import { FilterAction } from "./redux/actions/FilterActions";
import { MessageAction } from "./redux/actions/MessageActions";
import { DataAction } from "./redux/actions/DataActions";
import { backendServer } from "./variables";
import ExportModal from "./ExportModal";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  addMessage: (message, type) =>
    dispatch(MessageAction.addMessage(message, type)),
  setFromDate: (date) => dispatch(FilterAction.setFromDate(date)),
  setToDate: (date) => dispatch(FilterAction.setToDate(date)),
  toggleDetailedValues: () => dispatch(FilterAction.toggleDetailedValues()),
  toggleSensorFilter: (sensor) => {
    return () => {
      dispatch(FilterAction.toggleSensorFilter(sensor));
      dispatch(DataAction.updateChartData());
    }
  },
  toggleFilters: () => dispatch(FilterAction.toggleFilters()),
  filterClick: (evt) => {
    if (evt.target.type === "radio") {
      switch (evt.target.id) {
        case "option2": {
          dispatch(FilterAction.selected24h());
          break;
        }
        case "option4": {
          dispatch(FilterAction.selected1weed());
          break;
        }
      }
    }
  },
});

require("moment/locale/fi");

const isAnyHidden = (sensors) => {
  for (let key in sensors) {
    if (sensors[key] === false) {
      return true; // If any sensor has a value of false, return true
    }
  }
  return false; // If no property has a value of true, return false
}

class Filters extends Component {

  constructor() {
    super();
    this.state = {
    };
  }


  render() {
    let { filter, fromDate, toDate, showDetailedValues, filterOpen, sensors } =
      this.props.filterState;
    let { data } = this.props.dataState;
    let anyHidden = isAnyHidden(sensors);

    // console.log('Filter', this.props);
    let filterClass = "date-selection";
    if (filterOpen) {
      filterClass += " open";
    } else {
      filterClass += " closed";
    }

    const SensorFilter = data.map((item, key) => {
      if (typeof item !== "undefined" && item.deviceid && !sensors[item.deviceid]) {
        return (
          <label key={key + "frag"}>
            <input
              onChange={this.props.toggleSensorFilter(item.deviceid)}
              checked={sensors[item.deviceid]}
              type="checkbox"
            />
            {item.name || item.deviceid}
          </label>
        );
      }
    });
    return (
      <>
        <ExportModal
          isOpen={this.state.openExport}
          onClose={this.closeExportModal}
      />

        <div className={filterClass}>
          <div className="content">
            <h3>Aikarajaus</h3>
            <div className="from">
              <label>Mistä</label>
              <Datetime closeOnSelect={true} className="dateinput" value={fromDate} onChange={this.props.setFromDate} />
            </div>
            <div className="to">
              <label>Mihin</label>
              <Datetime closeOnSelect={true} className="dateinput" value={toDate} onChange={this.props.setToDate} />
            </div>
            <div
              className="btn-group btn-group-toggle"
              data-toggle="buttons"
              onClick={this.props.filterClick}
            >
              <label
                className={
                  filter === "option2"
                    ? "active button btn btn-secondary"
                    : "button btn btn-secondary"
                }
              >
                <input
                  type="radio"
                  name="options"
                  id="option2"
                  autoComplete="off"
                />{" "}
                24h
              </label>
              <label
                className={
                  filter === "option4"
                    ? "active button btn btn-secondary"
                    : "button btn btn-secondary"
                }
              >
                <input
                  type="radio"
                  name="options"
                  id="option4"
                  autoComplete="off"
                />{" "}
                Viikko
              </label>
            </div>
            {anyHidden?<>
              <h3>Näytä anturit</h3>
              {SensorFilter}
              </>:null}
            <h3>Lisätiedot</h3>
            <label>
              <input
                type="checkbox"
                value={showDetailedValues}
                onClick={this.props.toggleDetailedValues}
              />
              Näytä enemmän
            </label>
            <h3>Tallennus</h3>
            <button className="button button-secondary" onClick={this.openExportModal}>Vie kaikki tiedostoon</button>

            {/*<div
            className="btn-group btn-group-toggle"
            data-toggle="buttons"
            style={{ marginLeft: '10px' }}
          >
            <label
              className="button btn btn-secondary"
              onClick={this.filterBack}
            >
              <input
                type="radio"
                name="options"
                id="filterBack"
                autoComplete="off"
              />{' '}
              &lt;
            </label>
            <label
              className="button btn btn-secondary"
              onClick={this.filterForward}
            >
              <input
                type="radio"
                name="options"
                id="filterForward"
                autoComplete="off"
              />{' '}
              &gt;
            </label>
          </div>
          */}
          </div>
          <button className="visibility-btn" onClick={this.props.toggleFilters}>
            {filterOpen ? <span>&laquo;</span> : <span>&raquo;</span>}
          </button>
        </div>
      </>
    );
  }
  openExportModal = () => {
    this.setState({ openExport: true });
  };
  closeExportModal = (shouldExport) => () => {
    this.setState({ openExport: false });
    if (shouldExport) {
      this.exportAll();
    }
  };

  exportAll = async () => {
    const token = this.props?.loginState?.token;
    try {
      let res = await fetch(backendServer + "exportAll", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
          "x-api-key": "mIRmmtVxKs1JvsfZW8Bf74IWNqxiUCdl4KkFXrk1",
        },
      });
      if (res.status == 200) {
        this.props.addMessage("Tallennus käynnissä... Tarkista sähköpostisi.", "success")
      } else {
        this.props.addMessage("Tallennus epäonnistui. Ota yhteyttä ylläpitoon.", "warning")
      }
    } catch (e) {
      this.props.addMessage("Tallennus epäonnistui. Ota yhteyttä ylläpitoon.", "warning")

    }
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
