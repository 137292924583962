import { initialDataState } from "../state/initial-state/InitialDataState";

export const dataReducer = (state = initialDataState, action) => {
  switch (action.type) {
    case "SET_MINMAX_DATA":
      return {
        ...state,
        [action.payload.deviceId]: {
          ...state[action.payload.deviceId],
          minmaxData: action.payload.data,
        },
      };
    case "API_CHANGED": {
        state.data.forEach(element => {
          if (element.deviceid === action.payload.deviceid) {
            element.api = action.payload.apikey;
          }
        });

        return {
          ...state,
          data: [...state.data]
        }
      }
    case "SET_DATA": {
      let updateNeeded = false;
      if (action.payload) {
        if (action.payload.fromDate || action.payload.toDate) {
          updateNeeded = true;
        }
      }
      return {
        ...state,
        ...action.payload,
        updateNeeded,
      };
    }
    case "NAME_CHANGING":
    case "NAME_CHANGED": {
      const dataIndex = state.data.findIndex(
        (element) => element.deviceid === action.payload.device
      );
      if (dataIndex !== -1) {
        let newState = { ...state };
        newState.data[dataIndex].name = action.payload.name;
        return newState;
      }
      return { ...state };
    }
    case "LOGOUT":
      return { ...initialDataState };
    default:
      return { ...state };
  }
};
