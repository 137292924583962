import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import MinMaxChart from "./MinMaxChart";
import Datetime from "react-datetime";


const mapStateToProps = (state) => ({
  ...state,
});

class CurrentValueModal extends Component {
  constructor() {
    super();
    this.state = {
        fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        toDate: new Date(),
    };
  }

  setFromDate = () => {
    return (evt) => {
        this.setState({fromDate: evt.toDate()});
    }
  }

  setToDate = () => {
    return (evt) => {
        this.setState({toDate: evt.toDate()});
    }
  }


  render() {
    const deviceid = this.props.deviceid;
    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel="Anturin arvo" ariaHideApp={false}>
        <div className="modal-header">
          <h2>Valitse aikaväli</h2>
          <Datetime closeOnSelect={true} className="dateinput" value={this.state.fromDate} onChange={this.setFromDate()} />
          <div>&mdash;</div>
          <Datetime closeOnSelect={true} className="dateinput" value={this.state.toDate} onChange={this.setToDate()} />

          <button onClick={this.props.onClose()}>Sulje</button>
        </div>
        <div className="modal-body">
            <MinMaxChart deviceId={deviceid} fromDate={this.state.fromDate} toDate={this.state.toDate} dataType={this.props.dataType} />
        </div>
      </ReactModal>
    );
  }
}
export default connect(mapStateToProps)(CurrentValueModal);
