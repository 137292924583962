import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import * as FileSaver from "file-saver";

const mapStateToProps = (state) => ({
  ...state,
});

class CurrentValueModal extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  export = () => {
    var canvas = document.getElementById("qrcode");
        canvas.toBlob(function(blob) {
            FileSaver.saveAs(blob, "qrcode.png");
        });
  }

  render() {
    const deviceid = encodeURIComponent(this.props.deviceid);
    const apikey = encodeURIComponent(this.props.apikey);
    const addr = "/current.html?device=" + deviceid + "&apikey=" + apikey
    const longAddr = "https://mitattu.fi" + addr; 

    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel="Anturin arvo" ariaHideApp={false}>
        <div className="modal-header">
          <h2>Anturin nykyisen arvon näyttäminen</h2>
          {/* <button onClick={this.props.onClose(false)}>Sulje</button> */}
        </div>
        <div className="modal-body">
            <div style={{display: "flex"}}>
                <div>
                    <p>Voit seurata anturin nykyistä arvoa helposti osoitteessa:
                        <br />
                        <a href={addr} target="_blank" style={{overflowWrap: "anywhere"}} rel="noreferrer">{longAddr}</a>
                    </p>
                    <p>Huomaa, että tämä linkki ei vaadi kirjautumista. Kuka tahansa näkee sitä käyttämällä anturin nykyiset arvot.
                        <br />
                        Jos epäilet että linkki on päätynyt henkilölle, jonka et halua pääsevän näkemään nykyisiä arvoja, voit vaihtaa linkin koska tahansa. Tällöin vanha linkki ei enää toimi.</p>
                </div>
            <div style={{display: "flex", flexDirection: "column", padding: "0 40px"}}>
                <p>QR-koodi, jonka voi teipata anturiin ja puhelimella lukea anturin arvon.</p>
                <div>
                    <QRCode id="qrcode" value={longAddr} includeMargin={true} />
                    <a href="#" onClick={this.export}>
                        Tallenna kuva
                    </a>
                </div>
                
                <a
                    href="#"
                    onClick={() => this.props.changeApi(this.props.deviceid)}
                >
                    Vaihda osoite
                </a>
            </div>

            </div>
        </div>
        <div className="modal-footer" style={{justifyContent: "flex-start"}}>
          <button
            className="button button-secondary"
            onClick={this.props.onClose(false)}
          >
            Sulje
          </button>
        </div>
      </ReactModal>
    );
  }
}
export default connect(mapStateToProps)(CurrentValueModal);
