import { initialMessageState } from "../state/initial-state/InitialMessageState";

export const messageReducer = (state = initialMessageState, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return { ...state, ...action.payload };
    case "CLOSE_MESSAGE":
      return initialMessageState;
    default:
      return { ...state };
  }
};
