export function getCookie(name) {
  let cookie = {};
  document.cookie.split(";").forEach(function (el) {
    let [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function round(value, places) {
  let number = value;
  if (typeof value === "string") {
    number = Number.parseFloat(value);
    if (Number.isNaN(number)) {
      return 0;
    }
  }
  return +(Math.round(number + "e+" + places) + "e-" + places);
}
