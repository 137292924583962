import { initialRecoveryState } from "../state/initial-state/InitialRecoveryState";

export const recoveryReducer = (state = initialRecoveryState, action) => {
  switch (action.type) {
    case "RECOVERY_TOKEN_VALID":
      return {
        ...state,
        tokenStatus: "valid",
      };
    case "RECOVERY_TOKEN_INVALID":
      return {
        ...state,
        tokenStatus: "invalid",
      };
    case "RECOVERY_TOKEN_ERROR":
      return {
        ...state,
        tokenStatus: "error",
      };

    case "RECOVERY_EMAIL_SENT":
      return {
        ...state,
        changeSuccess: false,
      };
    case "RECOVERY_PASSWORD_ERROR":
      return {
        ...state,
        changeSuccess: false,
        message: "Virhe salasanan vaihdossa. Yritä myöhemmin uudelleen.",
      };
    case "RECOVERY_PASSWORD_CHANGED":
      return {
        ...state,
        changeSuccess: true,
        message: false,
      };
    default:
      return { ...state };
  }
};
