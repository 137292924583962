import React, { Component, PureComponent } from "react";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

import domtoimage from "dom-to-image";
import { round } from "./utils";
import CustomTooltipContent from "./CustomTooltipContent";
import { ReactComponent as ZoomIn } from "./zoom_in-24px.svg";
import { ReactComponent as ZoomOut } from "./zoom_out-24px.svg";
import { ReactComponent as SaveBtn } from "./add_photo_alternate-24px.svg";
import MinMaxModal from "./MinMaxModal";
import { ReactComponent as QueryIcon } from "./images/query_stats.svg";


function gen4() {
  return Math.random().toString(16).slice(-4);
}

function simpleUniqueId(prefix) {
  return (prefix || "").concat(
    [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join("")
  );
}

export default class Chart extends Component {
  constructor() {
    super();
    this.state = {
      maximized: false,
      id: simpleUniqueId("chart-"),
    };
  }

  toggleMaximize = () => {
    this.setState({ maximized: !this.state.maximized });
  };

  saveChart = () => {
    const fileName =
      (this.props.sensorName.name || this.props.sensorName.deviceid) +
      "_" +
      this.props.title +
      "_" +
      moment().format("D.M.YYYY HH:mm") +
      ".svg";
    domtoimage
      .toSvg(document.getElementById(this.state.id), { bgcolor: "#ffffff" })
      .then(function (blob) {
        window.saveAs(blob, fileName);
      });
  };

  render() {
    const { data, title, xName, average, unit, divider=1 } = this.props;
    if (
      !Array.isArray(data) ||
      data.length === 0 ||
      typeof data[data.length - 1][xName] === "undefined"
    ) {
      return null;
    }

    //check that user has right to see the data
    let dataUntil = this.props.sensorName.until;
    if (dataUntil > 0) {
      if (dataUntil < 15719230590) {
        dataUntil *= 1000;
      }
      if (Date.now() > dataUntil) {
        return null;
      }
    }
    //Tasmota plug need to show yesterday's data in the long time graph
    let dataType = this.props.xName;
    if (dataType === 'power') {
      dataType = 'yesterday';
    }
    let xName2 = xName + '2';
    return (
      <>
        <MinMaxModal
          isOpen={this.state.minMaxModalOpen}
          onClose={() => () => this.setState({minMaxModalOpen: false})}
          deviceid={this.props.sensorName.deviceid}
          name=""
          dataType={dataType}
        />
        <div
          className={this.state.maximized ? "chart chart-maximized" : "chart"}
        >
          <div className="title-with-action">
            <h3>{title}</h3>
            {this.state.maximized ? (
              <button onClick={this.toggleMaximize}>
                <ZoomOut title="Pienennä" role="button" width="40" />
                Pienennä
              </button>
            ) : (
              <button onClick={this.toggleMaximize}>
                <ZoomIn title="Suurenna" role="button" width="40" />
                Suurenna
              </button>
            )}
            <button onClick={this.saveChart}>
              <SaveBtn title="Tallenna" role="button" width="40" />
              Tallenna
            </button>
            <button className="btn-save" onClick={() => this.setState({minMaxModalOpen: true})}>
              <QueryIcon />
              Pitkä
            </button>
          </div>
          <div id={this.state.id}>
            <ResponsiveContainer aspect={4.0 / 3.0}>
              <LineChart
                data={data}
                syncId="syncId"
                margin={{
                  top: 5,
                  right: 20,
                  left: -10,
                  bottom: 45,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  scale="time"
                  type="number"
                  interval="preserveEnd"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={(x) =>
                    moment(parseInt(x)).format("D.M. HH:mm")
                  }
                  tick={<CustomizedAxisTick />}
                />
                <YAxis
                  scale="linear"
                  allowDataOverflow={true}
                  reversed={false}
                  tickFormatter={(formats) => round(formats / divider, 1)}
                  domain={["dataMin", "dataMax"]}
                />
                <Tooltip
                  contentStyle={{
                    background: "none",
                    border: "none",
                    fontSize: "12px",
                  }}
                  itemStyle={{ margin: 0, padding: 0, color: "#333" }}
                  content={
                    <CustomTooltipContent name={title} average={average} />
                  }
                  formatter={(formats) => round(formats / divider, 1) + (unit || "")}
                  labelFormatter={(x) =>
                    moment(parseInt(x)).format("D.M.YYYY HH:mm")
                  }
                />
                <ReferenceLine
                  y={average.mean}
                  stroke="#777777"
                  strokeDasharray="3 3"
                />
                <Line
                  type="monotone"
                  dataKey={xName}
                  stroke="#8884d8"
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey={xName2}
                  stroke="#d88884"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    const value = moment(parseInt(payload.value)).format("D.M. HH:mm");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {value}
        </text>
      </g>
    );
  }
}
