import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import Input from "./components/Input";

export default class Yhteystiedot extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      address: "",
      postalcode: "",
      postalcity: "",
      comment: "",
      forward: false,
    };
  }

  render() {
    if (this.state.forward) {
      return <Redirect to="/kiitos" />;
    }
    return (
      <>
        <div className="contact-page">
          <Helmet
            title="Yhteystiedot ja hinnasto - mitattu.fi"
            meta={[
              {
                name: "description",
                content: "Sisäilmamittauksen hinnasto ja yhteydenotto",
              },
            ]}
          />
          <div className="pricelist">
            <h1>Hinnasto</h1>
            <h2>Anturisto</h2>
            <h3>Lämpötila + kosteus</h3>
            <dl>
              <dt>Hinta</dt>
              <dd>70 €</dd>
            </dl>
            <h3>Hiilidioksidi (CO2) + lämpötila + kosteus</h3>
            <dl>
              <dt>Hinta</dt>
              <dd>165 €</dd>
            </dl>
            <h3>Paine-ero + lämpötila</h3>
            <dl>
              <dt>Hinta</dt>
              <dd>165 €</dd>
            </dl>
            <h3>VOC + lämpötila + kosteusanturi</h3>
            <dl>
              <dt>Hinta</dt>
              <dd>165 €</dd>
            </dl>
            <h3>PM2.5 + PM10 + lämpötila + kosteus</h3>
            <dl>
              <dt>Hinta</dt>
              <dd>165 €</dd>
            </dl>
            <h2>Vuokraus</h2>
            <dl>
              <dt>Hinta / anturi</dt>
              <dd>50 € / vko</dd>
            </dl>
            <h2>Toimitus</h2>
            <dl>
              <dt>Postitse</dt>
              <dd>30 €</dd>
            </dl>
            <h2>Verkkoliikennemaksu</h2>
            <dl>
              <dt>Anturien verkkoliikenne</dt>
              <dd>3,5&nbsp;€/kk/anturi</dd>
            </dl>
            <h2>Takuu</h2>
            <dl>
              <dt>Laitteiden takuu</dt>
              <dd>1 vuosi</dd>
              <dt>Laitteiden elinikä</dt>
              <dd>Noin&nbsp;5&nbsp;vuotta</dd>
            </dl>
          </div>
          <div className="contact-form">
            <h1>Ota yhteyttä</h1>
            <Input label="Nimi" id="name" onChange={this.onChangeEvent} />
            <Input
              label="Sähköposti"
              id="email"
              type="email"
              onChange={this.onChangeEvent}
            />
            <Input
              label="Puhelinnumero"
              id="phone"
              type="tel"
              onChange={this.onChangeEvent}
            />
            <Input
              label="Lähiosoite"
              id="address"
              onChange={this.onChangeEvent}
            />
            <Input
              label="Postinumero"
              id="postalcode"
              className="postal-code"
              onChange={this.onChangeEvent}
            />
            <Input
              label="Postitoimipaikka"
              id="postalcity"
              className="postal-city"
              onChange={this.onChangeEvent}
            />
            <label>
              <span className="full">Tarkempi kuvaus tavoitteistasi</span>
              <textarea
                id="comment"
                name="comment"
                onChange={this.onChangeEvent}
              ></textarea>
            </label>
            <input
              type="submit"
              onClick={this.postForm}
              className="button"
              value="Lähetä"
            />
            <p style={{ color: "red" }}>{this.state.error}</p>
          </div>
        </div>
      </>
    );
  }

  onChangeEvent = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  postForm = () => {
    try {
      fetch(
        "https://zk37skcbae.execute-api.eu-west-1.amazonaws.com/dev/email/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.state),
        }
      ).then((response) => {
        if (response.status === 200) {
          this.setState({ forward: true });
        } else {
          this.setState({
            error:
              "Lähetys ei onnistunut. Laita ystävällisesti kysymys sähköpostilla.",
          });
        }
      });
    } catch (err) {
      console.log(err);
      this.setState({
        error:
          "Lähetys ei onnistunut. Laita ystävällisesti kysymys sähköpostilla.",
      });
    }
  };
}
