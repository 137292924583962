import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import AttachDeviceModal from "./AttachDeviceModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { Helmet } from "react-helmet";
import moment from "moment";

import { connect } from "react-redux";
import { SettingAction } from "./redux/actions/SettingActions";
import { DataAction } from "./redux/actions/DataActions";
import { MessageAction } from "./redux/actions/MessageActions";

import { ReactComponent as EditIcon } from "./edit-24px.svg";


const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  clearSettings: () => dispatch(SettingAction.clearSettings()),
  updateChartData: () => dispatch(DataAction.updateChartData()),
  changePassword: (username, password) =>
    dispatch(SettingAction.changePassword(username, password)),
  addMessage: (message, type) =>
    dispatch(MessageAction.addMessage(message, type)),
  removeUser: (user) => dispatch(SettingAction.removeUser(user)),
  getAddedUsers: () => dispatch(SettingAction.getAddedUsers()),
  deleteDeviceFromUser: (deviceid, user) =>
    dispatch(SettingAction.deleteDeviceFromUser(deviceid, user)),
  addDevice: (addDeviceFor, deviceToAdd, deviceType, fromDate, toDate) =>
    dispatch(
      SettingAction.addDevice(
        addDeviceFor,
        deviceToAdd,
        deviceType,
        fromDate,
        toDate
      )
    ),
  updateUserType: (username, currentType) =>
    dispatch(SettingAction.updateUserType(username, currentType)),
  changeDeviceName: (deviceid, currentName) => {
    return () => {
      const enteredName = prompt("Anna anturille nimi", currentName);
      if (enteredName && enteredName.length > 0) {
        dispatch(SettingAction.changeDeviceName(deviceid, enteredName));
      }
    };
  },
});

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      addDeviceFor: false,
      changePassword: false,
    };
  }
  componentDidMount() {
    const { userType } = this.props.loginState;
    if (userType === "admin" || userType === "super") {
      this.props.getAddedUsers();
    }
  }
  componentDidUpdate(prevProps) {
    // page refresh has happened so load all data again...
    if (
      this.props.loginState.initializing === false &&
      prevProps.loginState.initializing === true &&
      this.props.loginState.token
    ) {
      //redo this step
      this.componentDidMount();
      this.props.updateChartData();
    }
  }
  render() {
    const { users } = this.props.settingsState;
    const { username, userType, initializing } = this.props.loginState;
    if (!username && !initializing) {
      this.props.addMessage("Ole hyvä ja kirjaudu ensin.", "warning");
      return <Redirect to="/login" />;
    }

    let createMyUserRow = () => {
      return {
        username: username,
        devices: this.props.dataState.data,
      };
    };

    let AddedUsersTable = () => {
      return (
        <>
          <UserRow
            change={this.changeUserType}
            data={createMyUserRow()}
            removeAction={this.askToRemove(username)}
            addSensor={this.openSensorAddModal(username)}
            deleteDevice={this.deleteDevice(username)}
            openPasswordModal={this.openPasswordModal(username)}
            editName={this.props.changeDeviceName}
          />
          {users && users.length
            ? users.map((user, index) => {
                return (
                  <UserRow
                    change={this.changeUserType}
                    data={user}
                    key={index}
                    removeAction={this.askToRemove(user.username)}
                    addSensor={this.openSensorAddModal(user.username)}
                    deleteDevice={this.deleteDevice(user.username)}
                    openPasswordModal={this.openPasswordModal(user.username)}
                    myType={userType}
                    editName={this.props.changeDeviceName}
                  />
                );
              })
            : null}
          {userType === "super" || userType === "admin" ? (
            <div className="button-add">
              <Link className="button" to="/addUser">
                Lisää käyttäjä
              </Link>
            </div>
          ) : null}
        </>
      );
    };
    return (
      <>
        <Helmet title="Asetukset - mitattu.fi" />
        <AttachDeviceModal
          isOpen={this.state.addDeviceFor}
          onClose={this.closeSensorAddModal}
        />
        <ChangePasswordModal
          isOpen={this.state.changePassword}
          onClose={this.closePasswordModal}
          username={this.state.changePassword}
        />

        <AddedUsersTable />
        <div className="cancel-button">
          <Link to="data" className="button">
            Peruuta
          </Link>
        </div>
      </>
    );
  }

  changeUserType = (user, type) => {
    return () => {
      this.props.updateUserType(user, type);
    };
  };

  askToRemove = (user) => {
    return () => {
      if (
        window.confirm("Oletko varma, että haluat poistaa käyttäjän: " + user)
      ) {
        if (window.confirm("Toimintoa ei voi perua. Oletko varma?")) {
          this.props.removeUser(user);
        }
      }
    };
  };

  deleteDevice = (user) => {
    return (deviceid, devicename) => {
      return () => {
        if (
          window.confirm("Poista käyttäjän " + user + " laite " + devicename)
        ) {
          if (window.confirm("Toimintoa ei voi perua. Oletko varma?")) {
            this.props.deleteDeviceFromUser(deviceid, user);
          }
        }
      };
    };
  };

  openSensorAddModal = (user) => {
    return () => {
      this.setState({ addDeviceFor: user });
    };
  };
  closeSensorAddModal = (save, deviceToAdd, deviceType, fromDate, toDate) => {
    return () => {
      if (save) {
        this.props.addDevice(
          this.state.addDeviceFor,
          deviceToAdd,
          deviceType,
          fromDate,
          toDate
        );
      }
      this.setState({ addDeviceFor: false });
      this.props.clearSettings();
    };
  };

  openPasswordModal = (user) => {
    return () => {
      this.setState({ changePassword: user });
    };
  };

  closePasswordModal = (username, password) => {
    return () => {
      if (username) {
        this.props.changePassword(username, password);
      }
      this.setState({ changePassword: false });
      this.props.clearSettings();
    };
  };
}

const UserRow = (props) => {
  if (!props.data || !props.data.username) {
    return null;
  }
  return (
    <>
      <div className="user-data-row">
        <h2>{props.data.username}</h2>
        <button
          onClick={props.openPasswordModal}
          className="button button-secondary"
        >
          Vaihda salasana
        </button>
        <button onClick={props.removeAction} className="button remove-user">
          Poista tunnus
        </button>
      </div>
      {props.myType === "super" ? (
        <div className="data-row">
          <span className="label">Tyyppi</span>
          <span className="data">
            {props.data.type}
            <button
              className="button button-in-table"
              onClick={props.change(props.data.username, props.data.type)}
            >
              Vaihda
            </button>
          </span>
        </div>
      ) : null}
      <h3>Sensorit</h3>
      <table className="device-table">
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Käyttöoikeus alkaa</th>
            <th>Käyttöoikeus päättyy</th>
            <th>Poista käyttöoikeus</th>
          </tr>
        </thead>
        <tbody>
          {props.data.devices.map((device, index) => {
            return (
              <DeviceRow
                key={index}
                data={device}
                deleteDevice={props.deleteDevice}
                editName={props.editName}
              />
            );
          })}
        </tbody>
      </table>
      <button onClick={props.addSensor} className="button button-secondary">
        Liitä uusi sensori
      </button>
    </>
  );
};

const DeviceRow = (props) => {
  const device = props.data;
  const since = parseInt(device.since);
  let sinceTxt = "Ei määritelty";
  if (since > 0) {
    sinceTxt = moment.unix(parseInt(since)).format("D.M.YYYY");
  }
  const until = parseInt(device.until);
  let untilTxt = "Ei määritelty";
  if (until > 0) {
    untilTxt = moment.unix(parseInt(until)).format("D.M.YYYY");
  }
  return (
    <tr>
      <td
        className="edit-device-name"
        onClick={props.editName(device.deviceid, device.name)}
      >
        {device.name || device.deviceid} <EditIcon />{" "}
      </td>
      <td>{sinceTxt}</td>
      <td>{untilTxt}</td>
      <td>
        <a href="#" onClick={props.deleteDevice(device.deviceid, device.name)}>
          Poista
        </a>
      </td>
    </tr>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
