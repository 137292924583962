import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import React from "react";

const CustomTooltipContent = (props) => {
  // payload[0] doesn't exist when tooltip isn't visible
  if (props !== null && props.payload !== null && props.payload[0] != null) {
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    const newPayload = [
      ...props.payload,
      {
        name: "Mediaani",
        // all your data which created the tooltip is located in the .payload property
        value: props.average.mean,
        // you can also add "unit" here if you need it
      },
    ];

    newPayload[0].name = props.name;
    if (newPayload[1]?.name == 'temperature2') {
      newPayload[1].name = 'Lämpötila2';
    }
    if (newPayload[1]?.name == 'humidity2') {
      newPayload[1].name = 'Kosteus2';
    }

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  // we just render the default
  return <DefaultTooltipContent {...props} />;
};

export default CustomTooltipContent;
