import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  ...state,
});

class AttachDeviceModal extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      error: false,
    };
  }

  setValue = (field) => {
    return (evt) => {
      let value = evt.target.value;
      this.setState({ [field]: value });
    };
  };

  saveClick = (closeAction, username) => {
    return () => {
      if (this.state.password !== this.state.password2) {
        this.setState({ error: "Salasanat eivät täsmää" });
        return;
      }
      if (this.state.password.length < 3) {
        this.setState({ error: "Anna kelvollinen salasana" });
        return;
      }
      this.setState({ error: "" });
      closeAction(username, this.state.password)();
    };
  };

  render() {
    const username = this.props.username;

    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel="Vaihda salasana">
        <div className="modal-header">
          <h2>Vaihda salasana</h2>
          <button onClick={this.props.onClose(false)}>Sulje</button>
        </div>
        <div className="modal-body">
          {this.state.error ? (
            <>
              <p className="error">{this.state.error}</p>
            </>
          ) : null}
          <form>
            <label>Käyttäjätunnus</label>
            <input disabled={true} value={username} name="username" />
            <label>Salasana</label>
            <input
              type="password"
              name="password"
              value={this.state.password}
              autoComplete="new-password"
              onChange={this.setValue("password")}
            />
            <label>Salasana uudelleen</label>
            <input
              type="password"
              value={this.state.password2}
              onChange={this.setValue("password2")}
              autoComplete="off"
            />
          </form>
        </div>
        <div className="modal-footer">
          <button
            className="button button-secondary"
            onClick={this.props.onClose(false)}
          >
            Peruuta
          </button>
          <button
            className="button"
            onClick={this.saveClick(this.props.onClose, username)}
          >
            Vaihda
          </button>
        </div>
      </ReactModal>
    );
  }
}
export default connect(mapStateToProps)(AttachDeviceModal);
