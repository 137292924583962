import { initialLoginState } from "../state/initial-state/InitialLoginState";

export const loginReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, busy: true };
    case "LOGOUT":
      return { ...initialLoginState, token: false };
    case "LOGIN_REMEMBER":
    case "LOGIN_PASSWORD":
    case "LOGIN_PASSWORD2":
    case "LOGIN_USERNAME":
      return { ...state, ...action.payload };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.payload.token,
        username: action.payload.username,
        userType: action.payload.userType,
        busy: false,
        initializing: false,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        token: false,
        busy: false,
        initializing: false,
        username: "",
        userType: "",
      };
    default:
      return { ...state };
  }
};
