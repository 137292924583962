export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function parseQuery(str) {
  let toReturn = {};
  try {
    const query = str.substring(1);
    const params = query.split("&");
    for (let i = 0; i < params.length; i++) {
      const value = params[i].split("=");
      if (value.length === 2) {
        toReturn[value[0]] = value[1];
      }
    }
  } catch (err) {
    // nothing
  }
  return toReturn;
}
