import { initialFilterState } from "../state/initial-state/InitialFilterState";

export const filterReducer = (state = initialFilterState, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD_SENSOR_FILTER":
      if (
        state.sensors[action.sensor] === false ||
        state.sensors[action.sensor] === true
      ) {
        return { ...state };
      }
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.sensor]: true,
        },
      };
    case "TOGGLE_SENSOR_FILTER": {
      let localStatus = localStorage.getItem("filter");
      if (!localStatus) {
        localStatus = {};
      } else {
        try {
          localStatus = JSON.parse(localStatus);
        } catch (err) {
          localStatus = {};
        }
      }
      localStatus[action.sensor] = !state.sensors[action.sensor];
      localStorage.setItem("filter", JSON.stringify(localStatus));

      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.sensor]: !state.sensors[action.sensor],
        },
      };
    }
    default:
      return { ...state };
  }
};
