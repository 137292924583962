import { initialSettingsState } from "../state/initial-state/InitialSettingsState";

export const settingsReducer = (state = initialSettingsState, action) => {
  switch (action.type) {
    case "EDIT_SETTING":
      return { ...state, ...action.payload };
    case "PASSWORD_CHANGED":
      return { ...state, done: true, busy: false, error: "" };
    case "EDIT_SETTINGS_BUSY":
      return { ...state, busy: true };
    case "USER_ADDED":
      return { ...state, busy: false, done: true, error: "" };
    case "CLEAR_SETTINGS":
    case "LOGOUT":
      return { ...initialSettingsState };
    case "ADDED_USERS":
      return { ...state, users: action.payload.users };
    case "NAME_CHANGING":
      return { ...state };
    case "NAME_CHANGED":
      return { ...state };
    case "USER_TYPE_CHANGED": {
      let users = [...state.users];
      let index = users.findIndex(
        (item) => item.username === action.payload.userName
      );
      users[index].type = action.payload.newType;
      return { ...state, users };
    }
    case "USER_REMOVED": {
      let users = [...state.users];
      let index = users.findIndex(
        (item) => item.username === action.payload.username
      );
      users.splice(index, 1);
      return { ...state, users };
    }
    default:
      return { ...state };
  }
};
