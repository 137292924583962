const addMessage = (message, type, timeout = 6000, closable = true) => {
  return {
    type: "ADD_MESSAGE",
    payload: {
      message,
      type,
      timeout,
      closable,
    },
  };
};

const closeMessage = () => {
  return {
    type: "CLOSE_MESSAGE",
  };
};

export const MessageAction = {
  addMessage,
  closeMessage,
};
