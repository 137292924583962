import { backendServer } from "../../variables";

const setUsername = (username) => {
  return {
    type: "LOGIN_USERNAME",
    payload: {
      username: username,
    },
  };
};

const setPassword = (password) => {
  return {
    type: "LOGIN_PASSWORD",
    payload: {
      password: password,
    },
  };
};
const setRemember = (remember) => {
  return {
    type: "LOGIN_REMEMBER",
    payload: {
      remember,
    },
  };
};

const logout = () => {
  return async (dispatch) => {
    localStorage.removeItem('token');
    dispatch({
      type: "LOGOUT",
    });
    try {
      await fetch(backendServer + "logout", {
        method: "POST",
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message: "Olet nyt kirjautunut ulos.",
        },
      });
    } catch (error) {
      //no can do
    }
  };
};

const login = () => {
  return async (dispatch, getState) => {
    let { username, password, remember } = getState().loginState;
    dispatch({
      type: "LOGIN",
    });
    try {
      let res = await fetch(backendServer + "auth", {
        method: "POST",
        body: JSON.stringify({
          username,
          password,
          remember,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "mIRmmtVxKs1JvsfZW8Bf74IWNqxiUCdl4KkFXrk1",
        },
      });
      console.log()
      if (res.status === 200) {
        let data = await res.json();
        localStorage.setItem("token", data.token);
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            token: data.token,
            username: data.username,
            userType: data.userType,
          },
        });
      } else {
        dispatch({
          type: "LOGIN_FAIL",
        });
        dispatch({
          type: "ADD_MESSAGE",
          payload: {
            message: "Tarkista käyttäjätunnus ja salasana",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "LOGIN_FAIL",
      });
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          message:
            "Jotain meni pieleen. Tarkista nettiyhteys ja yritä uudelleen.",
        },
      });
    }
  };
};

const checkToken = () => {
  return async (dispatch, getState) => {
    let { token, username } = getState().loginState;
    //console.log('Checking token:', token);
    if (token && username) {
      return;
    }
    dispatch({
      type: "LOGIN",
    });
    try {
      let res = await fetch(backendServer + "checkToken", {
        method: "POST",
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "x-api-key": "mIRmmtVxKs1JvsfZW8Bf74IWNqxiUCdl4KkFXrk1",
        },
      });
      if (res.status === 200) {
        let resJson = await res.json();
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            token: res.headers.get("x-access-token"),
            username: resJson.username,
            userType: resJson.userType,
          },
        });
      } else {
        dispatch({
          type: "LOGIN_FAIL",
        });
      }
    } catch (e) {
      //this happens in react snap...
      dispatch({
        type: "LOGIN_FAIL",
      });
    }
  };
};

export const LoginAction = {
  login,
  setUsername,
  setPassword,
  logout,
  checkToken,
  setRemember,
};
