import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./LockIcon.css";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = () => ({});

class UserNavi extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc);
  }

  handleEsc = (evt) => {
    if (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27) {
      this.props.hideNavi();
    }
  };

  logout = (evt) => {
    this.props.hideNavi();
    this.props.history.push("/logout");
    evt.preventDefault();
  };

  settings = (evt) => {
    this.props.hideNavi();
    this.props.history.push("/settings");
    evt.preventDefault();
  };
  data = (evt) => {
    this.props.hideNavi();
    this.props.history.push("/data");
    evt.preventDefault();
  };

  render() {
    const path = this.props.location.pathname;

    return (
      <>
        <div
          ref={(elem) => (this.hiddenBg = elem)}
          id="navi-hidden-bg"
          onClick={this.props.hideNavi}
        />
        <div id="user-navi">
          <ul>
            <li className={path === "/data" ? "active" : null}>
              <Link to="/data" onClick={this.data}>
                Data
              </Link>
            </li>
            <li className={path === "/settings" ? "active" : null}>
              <Link to="/settings" onClick={this.settings}>
                Asetukset
              </Link>
            </li>
            <li>
              <Link to="/logout" onClick={this.logout}>
                Kirjaudu ulos
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserNavi)
);
