import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./Boilerplate.css";
import "./Datepicker.css";
import "./LoginScreen.css";
import "./App.css";

import WebPages from "./WebPages";
import Logout from "./logout";
import Data from "./data";
import Settings from "./settings";
import AddUser from "./AddUser";
import ScrollToTop from "./ScrollToTop";
import TokenChecker from "./TokenChecker";
import Message from "./Message";
import Header from "./Header";

import { store } from "./config/config";

class App extends Component {
  constructor() {
    super();
    this.state = {
      cookiesAllowed: false,
    };
  }

  setCookieState = (allowed) => {
    this.setState({ cookiesAllowed: allowed });
  };


  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <TokenChecker />
            <Message />
            <Header />
            <Switch>
              <Route path="/data" component={Data} />
              <Route path="/settings" component={Settings} />
              <Route path="/addUser" component={AddUser} />
              <Route path="/logout" component={Logout} />
              <Route component={WebPages} />
            </Switch>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
