import { Component } from "react";
import { LoginAction } from "./redux/actions/LoginActions";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => ({
  checkToken: () => dispatch(LoginAction.checkToken()),
});

class TokenChecker extends Component {
  componentDidMount() {
    this.props.checkToken();
  }

  render() {
    return null;
  }
}

export default connect(null, mapDispatchToProps)(TokenChecker);
