import { backendServer } from "../../variables";

const sendEmailToUser = (email) => {
  return async (dispatch) => {
    const queryParameter = "?user=" + email;
    try {
      let res = await fetch(
        backendServer + "createUserRecoveryToken" + queryParameter,
        {
          method: "GET",
          headers: {
            "x-api-key": "mIRmmtVxKs1JvsfZW8Bf74IWNqxiUCdl4KkFXrk1",
          },
        }
      );
      if (res.status === 0 || res.status === 200) {
        dispatch({
          type: "RECOVERY_EMAIL_SENT",
        });
      } else {
        dispatch({
          type: "RECOVERY_INVALID",
        });
      }
    } catch (error) {
      dispatch({
        type: "RECOVERY_ERROR",
      });
    }
  };
};

const checkRecoveryToken = (user, token) => {
  return async (dispatch) => {
    const queryParameter = "?user=" + user + "&token=" + token;
    try {
      let res = await fetch(
        backendServer + "checkRecoveryToken" + queryParameter,
        {
          method: "GET",
          headers: {
            "x-api-key": "mIRmmtVxKs1JvsfZW8Bf74IWNqxiUCdl4KkFXrk1",
          },
        }
      );
      if (res.status === 200) {
        dispatch({
          type: "RECOVERY_TOKEN_VALID",
        });
      } else {
        dispatch({
          type: "RECOVERY_TOKEN_INVALID",
        });
      }
    } catch (error) {
      dispatch({
        type: "RECOVERY_TOKEN_ERROR",
      });
    }
  };
};

const recoveryChangePassword = (username, password, token) => {
  return async (dispatch) => {
    dispatch({
      type: "RECOVERY_BUSY",
    });

    try {
      let res = await fetch(backendServer + "recoveryChangePassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "mIRmmtVxKs1JvsfZW8Bf74IWNqxiUCdl4KkFXrk1",
        },
        body: JSON.stringify({
          username,
          password,
          token,
        }),
      });
      if (res.status === 200) {
        dispatch({
          type: "RECOVERY_PASSWORD_CHANGED",
        });

        dispatch({
          type: "ADD_MESSAGE",
          payload: {
            message: "Salasana vaihdettu",
          },
        });
      } else {
        dispatch({
          type: "RECOVERY_PASSWORD_ERROR",
        });
      }
    } catch (error) {
      dispatch({
        type: "RECOVERY_PASSWORD_FAULTY",
      });
    }
  };
};

export const RecoveryActions = {
  sendEmailToUser,
  checkRecoveryToken,
  recoveryChangePassword,
};
