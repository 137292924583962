const setFromDate = (fromDate) => {
  return async (dispatch, getState) => {

    let { toDate } = getState().filterState;
    const dateOffset = (24*60*60*1000) * 7; //7 days
    toDate = toDate.valueOf();
    fromDate = fromDate.valueOf();
    if (toDate - fromDate > dateOffset || toDate <= fromDate) {
      toDate = fromDate + (24*60*60*1000) * 3; //3 days
    }

    dispatch({
      type: "SET_FILTER",
      payload: {
        fromDate,
        toDate,
        filter: "none",
      },
    });
  }
};

const selected24h = () => {
  let toDate = new Date();
  const dateOffset = (24*60*60*1000) * 1; //1 days
  return {
    type: "SET_FILTER",
    payload: {
      fromDate: toDate.valueOf() - dateOffset,
      toDate: toDate.valueOf(),
      filter: "option2",
    },
  };
};

const selected1weed = () => {
  let toDate = new Date();
  const dateOffset = (24*60*60*1000) * 7; //1 days
  return {
    type: "SET_FILTER",
    payload: {
      fromDate: toDate.valueOf() - dateOffset,
      toDate: toDate.valueOf(),
      filter: "option4",
    },
  };
};

const setToDate = (toDate) => {
  return async (dispatch, getState) => {
    let { fromDate } = getState().filterState;
    fromDate = fromDate.valueOf();
    toDate = toDate.valueOf();
    const dateOffset = (24*60*60*1000) * 7; //7 days

    if (toDate - fromDate > dateOffset || toDate <= fromDate) {
      fromDate = toDate - (24*60*60*1000) * 3;
    }
    dispatch({
        type: "SET_FILTER",
        payload: {
          toDate,
          fromDate,
          filter: "none",
        },
      });
  };
};

const toggleFilters = () => {
  return async (dispatch, getState) => {
    let { filterOpen } = getState().filterState;
    dispatch({
      type: "SET_FILTER",
      payload: {
        filterOpen: !filterOpen,
      },
    });
  };
};

const toggleDetailedValues = () => {
  return (dispatch, getState) => {
    let { showDetailedValues } = getState().filterState;
    dispatch({
      type: "SET_FILTER",
      payload: {
        showDetailedValues: !showDetailedValues,
      },
    });
  };
};

const toggleSensorFilter = (sensor) => {
  return {
      type: "TOGGLE_SENSOR_FILTER",
      sensor,
  };
};

export const FilterAction = {
  setFromDate,
  setToDate,
  toggleDetailedValues,
  selected1weed,
  selected24h,
  toggleFilters,
  toggleSensorFilter,
};
