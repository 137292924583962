import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LockIcon from "./LockIcon";

import "./Navigation.css";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = () => ({});

class Navigation extends Component {
  render() {
    const path = this.props.location.pathname;
    const etusivuClass = path.length === 1 ? "active" : null;

    return (
      <nav>
        <ul>
          <li className={etusivuClass}>
            <Link to="/">Etusivu</Link>
          </li>
          <li className={path.startsWith("/taustaa") ? "active" : null}>
            <Link to="/taustaa/">Taustaa</Link>
          </li>
          <li className={path.startsWith("/yhteydenotto") ? "active" : null}>
            <Link to="/yhteydenotto/">Ota yhteyttä</Link>
          </li>
          <LockIcon />
        </ul>
      </nav>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
