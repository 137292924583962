import React from "react";
import { Link } from "react-router-dom";

import "./Logo.css";
import logo from "./logo.png";

/*eslint react/display-name: ["off"]*/
export default () => {
  return (
    <>
      <Link className="logo" to="/">
        <img src={logo} alt="Mitattu - terveellistä sisäilmaa" />
        <span className="sr-only">Etusivulle</span>
      </Link>
    </>
  );
};
