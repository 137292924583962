import React from "react";
import App from "./App";
import "./index.css";
import { render } from "react-dom";

if (window.location.hostname.startsWith("www.")) {
  window.location = window.location.href.replace("www.", "");
}

const rootElement = document.getElementById("root");
render(<App />, rootElement);
