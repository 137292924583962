import moment from "moment";

require("moment/locale/fi");

const getFilter = () => {
  let localStatus = localStorage.getItem("filter");
  if (!localStatus) {
    localStatus = {};
  } else {
    try {
      localStatus = JSON.parse(localStatus);
    } catch (err) {
      localStatus = {};
    }
  }
  return localStatus;
};

export const initialFilterState = {
  fromDate: moment().subtract(3, "days").valueOf(),
  toDate: new Date().valueOf(),
  showDetailedValues: false,
  filter: "option3",
  filterOpen: true,
  sensors: getFilter(),
};
