import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class SalasanaaPalautetaan extends Component {
  render() {
    return (
      <>
        <Helmet title="Lomake lähetetty - mitattu.fi" />
        <h1>Salasanan palautus lähetetty</h1>
        <p>
          Saat toimintaohjeet pian sähköpostiisi. Jos postilaatikossa ei näy
          mitään, tarkista seuraavat asiat{" "}
        </p>
        <ul>
          <li>Onko viesti mennyt roskapostiin?</li>
          <li>Käytitkö varmasti samaa osoitetta kuin rekisteröityessäsi?</li>
          <li>
            Yleensä viesti saapuu välittömästi. Jos viestiä ei ole puoleen
            tuntiin tullut, joku meni varmaankin pieleen
          </li>
          <li>Älä epäröi ottaa yhteyttä ongelmatilanteissa</li>
        </ul>
        <p className="thanks">
          <Link className="button" to="/">
            Etusivulle
          </Link>
        </p>
      </>
    );
  }
}
